<template>
	<div class="news-card" @click="onClick">
		<div class="news-card__details">
			<div class="news-card__top-info">
				<span class="news-card__source headline-s" v-if="source">
					{{ source }}
				</span>
				<span class="news-card__source headline-s" v-if="!source">...</span>
			</div>

			<div class="news-card__mid-info">
				<span class="news-card__name body-l">{{title}}</span>
			</div>

			<div class="news-card__bottom-info">
				<span class="news-card__view body-s">{{$t("components.candidate.newsCard.view-article")}}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "news-card",
	props: {
		source: {
			type: String,
			required: false,
		},
		title: {
			type: String,
			required: true,
		},
		link: {
			type: String,
			required: false,
		},
	},
	methods: {
		onClick() {
			window.open(this.link, "_blank");
		},
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';

.news-card
	display flex
	max-width $pxToRem(400)
	min-width $pxToRem(300)
	color: $black
	background-color $white
	border-radius $cardBorderRadius
	cursor pointer

	&__top-info
		display flex
		justify-content space-between

	&__source
		font-size: $pxToRem(18)

	&__mid-info
		margin-top $pxToRem(5)
		margin-bottom $pxToRem(5)

	&__name
		font-size: $pxToRem(21)

	&__view
		text-decoration: underline
		font-size: $pxToRem(14)

@media screen and (min-width $pxToRem(375))
	.news-card
		&__details
			padding $pxToRem(20)

@media screen and (min-width $pxToRem(465))
	.news-card
		&__view
			margin-right $pxToRem(10)
</style>
