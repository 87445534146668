<template>
	<div class="not-found-view">
		<not-found-content
		/>
	</div>
</template>

<script>
import NotFoundContent from "../components/NotFound/NotFoundContent/NotFoundContent.vue";

export default {
	name: "not-found-view",
	components: {
		NotFoundContent,
	},
};
</script>

<style lang="stylus" scoped>

</style>
