function InitializeFirebaseLegislations(config) {
	const { db } = config;

	return {
		name: "topics",

		async fetchNational(input) {
			return new Promise((resolve, reject) => {
				db
					.collection("V2Legislations")
					.doc(input.topic.replace(/\s/g, "").toLowerCase().trim())
					.collection("legislations")
					.where(
						"level",
						"==",
						"national",
					)
					.get()
					.then((querySnapshot) => {
						const output = {
							national: [],
							state: [],
							local: [],
						};
						querySnapshot.forEach((doc) => {
							output[doc.data().level].push(doc.data());
						});
						resolve(output);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},

		async fetchByZipCode(input) {
			return new Promise((resolve, reject) => {
				db
					.collection("V2Legislations")
					.doc(input.topic.replace(/\s/g, "").toLowerCase().trim())
					.collection("legislations")
					.where(
						"zipCodes",
						"array-contains",
						input.zipCode,
					)
					.get()
					.then((querySnapshot) => {
						const output = {
							national: [],
							state: [],
							local: [],
						};
						querySnapshot.forEach((doc) => {
							output[doc.data().level].push(doc.data());
						});
						resolve(output);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
	};
};

export default InitializeFirebaseLegislations;
