<template>
	<div class="team-list">
		<div class="team-list__card-block">
			<div
				v-for="member in teamMembers"
				:key="member.name"
				class="team-list__card"
			>
				<div class="team-list__image-frame">
					<!--
					<object class="team-list__image" :data="imagePrefix(member.image)" type="image/png">
						<img class="team-list__fallback" src="@/assets/fallback-image.jpg" :alt="member.name" />
					</object>
					-->
					<img class="team-list__image" @error="onImgErr" :src="imagePrefix(member.image)" :alt="member.name" />
				</div>
				<p class="team-list__name headline-xs">{{member.name}}</p>
				<p class="team-list__role body-s">{{member.role}}</p>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "team-list",
	props: {
		teamMembers: {
			type: Array,
			required: true,
		},
	},
	methods: {
		imagePrefix(imageName) {
			try {
				// eslint-disable-next-line import/no-dynamic-require, global-require
				return require(`../../../../public/team/${imageName}`);
			} catch {
				// eslint-disable-next-line import/no-dynamic-require, global-require
				return require("../../../assets/fallback-image.jpg");
			}
		},
		onImgErr(event) {
			const srcBits = event.target.src.split("/");

			if (srcBits[srcBits.length - 1] !== "fallback-image.jpg") {
				// eslint-disable-next-line no-param-reassign, global-require
				event.target.src = require("../../../assets/fallback-image.jpg");
			}
		},
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';

.team-list
	text-align: center

	&__card-block
		display: flex
		flex-wrap: wrap
		justify-content: space-evenly

	&__card
		display:inline-block
		vertical-align: top
		margin-left: 1rem
		margin-right: 1rem
		margin-bottom: 1rem

	&__image-frame
		width: 5rem
		height: 5rem
		border-radius: 50%
		box-shadow: 0px 10px 14px #00000026;

	&__image
		width: 5rem
		height: 5rem
		object-fit: cover
		border-radius: 10rem
		filter: grayscale(100%)
		transform-origin: center
		transform: scale(1)
		transition: all 0.4s

	&__card:hover div img.team-list__image
		filter: grayscale(0)
		transform: scale(1.05)
		transition: all 0.4s

	&__fallback
		width: 5rem
		height: 5rem
		object-fit: cover
		border-radius: 10rem

	&__name
		color: $white
		font-size: $pxToRem(16)
		max-width: 5rem
		margin-bottom: $pxToRem(10)

	&__role
		color: $white
		font-size: $pxToRem(11)
		line-height: 1.2
		max-width: 5rem
		margin-top: 0
		transform: translate(0, -100%)
		opacity: 0
		transition: all 0.4s

	&__card:hover p.team-list__role
		transform: translate(0, 0)
		opacity: 1
		transition: all 0.4s

@media screen and (min-width $pxToRem(420))
	.team-list

		&__card
			margin-left: 1.5rem
			margin-right: 1.5rem

		&__image-frame
			width: 7.5rem
			height: 7.5rem

		&__image
			width: 7.5rem
			height: 7.5rem

		&__name
			max-width: 7.5rem

		&__role
			max-width: 7.5rem

@media screen and (min-width $pxToRem(850))
	.team-list

		&__image-frame
			width: 10rem
			height: 10rem

		&__image
			width: 10rem
			height: 10rem

		&__fallback
			width: 10rem
			height: 10rem

		&__card
			margin-left: 2rem
			margin-right: 2rem

		&__name
			font-size: $pxToRem(21)
			max-width: 10rem

		&__role
			font-size: $pxToRem(16)
			max-width: 10rem

@media screen and (min-width $pxToRem(1000))
	.team-list
		&__image-frame
			width: 12rem
			height: 12rem

		&__image
			width: 12rem
			height: 12rem

		&__fallback
			width: 12rem
			height: 12rem

		&__card
			margin-left: 2.5rem
			margin-right: 2.5rem

		&__name
			font-size: $pxToRem(21)
			max-width: 12rem

		&__role
			font-size: $pxToRem(16)
			max-width: 12rem
</style>
