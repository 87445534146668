<template>
	<div class="event-list__container">
		<h1 class="event-list__header headline-xl">{{ $t("components.home.eventList.upcoming") }}</h1>
		<hr class="event-list__divider" />
		<event-card
			class="event-list__card"
			v-for="event in events"
			:key="event.eventTitle"
			v-bind="event"
		/>
		<button class="event-list__expand button--cta" @click="openLink('https://www.eventbrite.com/o/myvote-project-32612717105')">{{ $t("components.home.eventList.viewMore") }}</button>
	</div>
</template>

<script>
import EventCard from "../EventCard/EventCard.vue";

export default {
	name: "event",
	components: { EventCard },
	props: {
		events: {
			type: Array,
			required: true,
		},
	},
	computed: {
	},
	methods: {
		openLink(destination) {
			window.open(destination, "_blank");
		},
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';

.event-list
	&__header
		font-size: $subheader-font-size
		font-weight: $subheader-font-weight
		font-family: $subheader-font-family
		color: $purple

	&__divider
		border: none;
		height: 0.15rem
		color: $purple
		background-color: $purple
		margin-top: 1rem
		margin-bottom: 1rem

	&__card
		margin-top: 4rem

	&__expand
		background-color: $purple
		color: $white
		display: block
		margin-top: 4rem
		margin-bottom: 4rem
		margin-left: auto
		margin-right: auto
</style>
