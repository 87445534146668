<template>
	<div class="legislations-index-display">
		<div class="legislations-index-display__section--purple">
			<h1 class="legislations-index-display__legislation-header headline-xl">
				{{ copy.topics ? copy.topics[legislation-header] : $t("components.topics.topicDetail.legislation-header") }}
			</h1>
			<div class="legislations-index-display__legislation-zip body-s" v-if="zipCode">
				{{ copy.topics ? copy.topics[legislation-zip] : $t("components.topics.topicDetail.legislation-zip") }} <button class="legislations-index-display__zipcode body-s button--link" @click="handleZipSearchSubmit()">
					{{ zipCode }}
				</button>
			</div>

			<div class="legislations-index-display__controls">
				<locality-tabs
					class="legislations-index-display__locality-tabs"
					:selected="selected"
					@tabClicked="handleTabClicked"
				/>
			</div>
			<hr class="legislations-index-display__controls-divider" />

			<div class="legislations-index-display__content-boundary legislations-index-display__card-container">
				<!-- Controls -->
				<!-- Card display -->
				<!-- Use a separate component to handle empty state, offloading that logic -->
				<legislation-card
					class="legislations-index-display__legislation-card"
					v-for="legislation in legislations[selected]"
					:key="legislation.title"
					v-bind="legislation"
				/>
				<legislation-search-display
					class="candidates-index-display__search-display"
					v-if="!legislations[selected] || legislations[selected].length === 0"
					:zipCode="zipCode"
					@zipSearchSubmit="handleZipSearchSubmit"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import LocalityTabs from "../../Common/LocalityTabs/LocalityTabs.vue";
import LegislationCard from "../LegislationCard/LegislationCard.vue";
import LegislationSearchDisplay from "../LegislationSearchDisplay/LegislationSearchDisplay.vue";

export default {
	name: "legislations-index-display",
	components: {
		LocalityTabs,
		LegislationCard,
		LegislationSearchDisplay,
	},
	props: {
		copy: {
			type: Object,
			default() {
				return {};
			},
		},
		legislations: { //key: national, state, local
			type: Object,
			required: true,
		},
		zipCode: {
			type: String,
		},
	},
	data() {
		return {
			selected: "national",
			filter: "all",
		};
	},
	computed: {
		filteredCandidates() {
			if (!(this.candidates) || !(this.candidates?.[this.selected])) { return null; }

			const level = this.candidates?.[this.selected];

			if (this.filter === "all") { return level; }

			return { [this.filter]: level?.[this.filter] };
		},
		offices() {
			return Object.keys(this.candidates?.[this.selected] || {});
		},
	},
	methods: {
		handleTabClicked(level) {
			this.selected = level;
			this.filter = "all"; // Reset to avoid weird broken values
		},
		handleZipSearchSubmit(zipCode) {
			this.$emit("zipSearchSubmit", zipCode);
		},
	},
};
</script>

<style lang="stylus" scoped>
@import "../../../styles/global.styl"

.legislations-index-display
	margin-left auto
	margin-right auto

	&__section--purple
		background-color: $purple
		color: $white
		overflow: auto

	&__title
		margin-bottom: 0

	&__subtitle
		color: $lightest-purple
		margin-top: 0.5rem

	&__zipcode
		color: $white
		text-decoration: underline
		cursor: pointer

	&__content-boundary
		max-width: 85rem
		margin-left: auto
		margin-right: auto

	&__locality-tabs
		margin-top: 2rem

	&__level-display
		margin-bottom: 16rem

	&__search-display
		max-width: 40rem
		margin-top: 4rem
		margin-bottom: 12rem
		margin-left: auto
		margin-right: auto

	&__controls-divider
		border: none
		height: 0.15rem
		color: $white
		background-color: $white
		margin: 0
		margin-top: 2rem
		margin-bottom: 4rem

	&__legislation-card
		max-width: 100%
		margin-left: auto
		margin-right: auto
		margin-bottom: 2rem

	&__legislation-header
		color: $white
		margin-bottom: 1rem

	&__legislation-zip
		color: $lightest-purple
		margin-top: 0.5rem

	&__card-container
		padding-bottom: $pxToRem(120)
</style>
