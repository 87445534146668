<template>
	<div id="about-content-top" class="about-content">
		<header-bar class="about-content__header-bar" :inverted="false" style="visibility: hidden" /> <!-- TODO: replace this hacky spacer -->
		<header-bar class="about-content__header-bar" :inverted="false" :fixTop="true" />
		<div class="about-content__headline">
			<img class="about-content__header-image" src="@/assets/about-header.jpg" />
			<div class="about-content__headline__content-boundary">
				<h1 class="about-content__headline-header headline-xxl">
					{{ copy.about ? copy.about.header : $t("components.about.aboutContent.header") }}
				</h1>
			</div>
		</div>
		<div class="about-content__section--purple">
			<div class="about-content__content-section about-content__content-section--story">
				<h2 class="about-content__section-header headline-xl">
					{{ copy.about ? copy.about["story-header"] : $t("components.about.aboutContent['story-header']") }}
				</h2>
				<p class="about-content__section-overview body-l">
					<pre class="body-l">{{ copy.about ? copy.about["story-overview"] : $t("components.about.aboutContent['story-overview']") }}</pre>
				</p>
				<hr class="about-content__section-divider" />
			</div>
			<div class="about-content__content-section about-content__content-section--team">
				<h2 class="about-content__section-header headline-l">
					{{ copy.about ? copy.about["team-header"] : $t("components.about.aboutContent['team-header']") }}
				</h2>
				<div class="about-content__section-overview body-l">
					<team-list :teamMembers="teamMembers"/>
				</div>
				<hr class="about-content__section-divider" />
			</div>
			<div class="about-content__content-section about-content__content-section--mission">
				<h2 class="about-content__section-header headline-l">
					{{ copy.about ? copy.about["mission-header"] : $t("components.about.aboutContent['mission-header']") }}
				</h2>
				<p class="about-content__section-overview body-l">
					{{ copy.about ? copy.about["mission-overview"] : $t("components.about.aboutContent['mission-overview']") }}
				</p>
				<hr class="about-content__section-divider" />
			</div>
			<div class="about-content__content-section about-content__content-section--press">
				<h1 class="about-content__section-header headline-l">
					{{ $t("components.about.aboutContent['press-header']") }}
				</h1>
				<div class="about-content__section-overview body-l">
					<ul class="about-content__press-list">
						<li
							class="about-content__press-items"
							v-for="press in pressItems"
							:key="press.title"
						>
							<a target="_blank" :href="press.link">
								{{press.title}}
							</a>
						</li>
					</ul>
				</div>
				<hr class="about-content__section-divider" />
			</div>
			<div class="about-content__content-section about-content__content-section--volunteer">
				<h1 class="about-content__section-header headline-l">
					{{ copy.about ? copy.about["volunteer-header"] : $t("components.about.aboutContent['volunteer-header']") }}
				</h1>
				<p class="about-content__section-overview body-l">
					{{ copy.about ? copy.about["volunteer-overview"] : $t("components.about.aboutContent['volunteer-overview']") }}
				</p>
				<button class="about-content__cta-button--volunteer button--cta" @click="openLink('https://docs.google.com/forms/d/e/1FAIpQLSdVf76uJ8DJf3q3Qco4BC_ED1mfoCQDMuZr-q5K55g7RD5y_g/viewform')">{{ $t("components.about.aboutContent.volunteer-cta") }}</button>
			</div>
		</div>
		<div class="about-content__section--white">
			<div class="about-content__content-section about-content__content-section--donate">
				<h1 class="about-content__section-header headline-xxl">
					{{ copy.about ? copy.about["donate-headline"] : $t("components.about.aboutContent['donate-header']") }}
				</h1>
				<p class="about-content__section-overview body-l">
					{{ copy.about ? copy.about["donate-overview"] : $t("components.about.aboutContent['donate-overview']") }}
				</p>
				<button class="about-content__cta-button--donate button--cta" @click="openLink('https://edwardcharlesfoundation.networkforgood.com/projects/222923-myvoteproject')">{{ $t("components.about.aboutContent.donate-cta") }}</button>
				<p class="about-content__cta-alt body-s"><a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdVf76uJ8DJf3q3Qco4BC_ED1mfoCQDMuZr-q5K55g7RD5y_g/viewform">{{ $t("components.about.aboutContent.donate-cta-alt") }}</a></p>
				<footer-bar class="about-content__footer" :inverted="false"/>
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBar from "../../Common/HeaderBar/HeaderBar.vue";
import FooterBar from "../../Common/FooterBar/FooterBar.vue";
import TeamList from "../TeamList/TeamList.vue";

export default {
	name: "about-content",
	components: {
		HeaderBar,
		FooterBar,
		TeamList,
	},
	props: {
		copy: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	computed: {
		pressItems() {
			if (this.copy.about) {
				return this.copy.about.press;
			} else {
				return this.$t("components.about.aboutContent.press");
			}
		},
		teamMembers() {
			if (this.copy.about) {
				return this.copy.about["team-members"];
			} else {
				return this.$t("components.about.aboutContent.teammembers");
			}
		},
	},
	methods: {
		backnav() {
			this.$router.back();
		},
		openLink(destination) {
			window.open(destination, "_blank");
		},
	},
	mounted() {
		document.getElementById("about-content-top").scrollIntoView({ behavior: "smooth" });
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';
.about-content
	color: $white

	&__header-bar
		z-index: 100

	&__headline
		position: relative
		background-color: $light-purple
		padding-left: 4%
		padding-right: 4%
		overflow: hidden

	&__header-image
		display: block
		top: 5rem
		width: 100%
		height: auto
		margin-left: auto
		margin-right: auto
		text-align: center
		z-index: 0

	&__headline-header
		position: absolute
		font-size: 1.625rem
		bottom: 0
		z-index: 1

	&__section--purple
		background-color: $purple
		padding-left: 4%
		padding-right: 4%
		overflow: auto

	&__section--white
		background-color: $white
		color: $purple
		padding-left: 4%
		padding-right: 4%
		overflow: auto

	&__section-header
		margin-top: 5rem
		margin-bottom: 3rem

	&__section-overview
		overflow-wrap: anywhere
		margin-top: 2rem
		margin-bottom: 4rem

		& pre
			white-space: pre-wrap;
			white-space: -moz-pre-wrap;
			white-space: -pre-wrap;
			white-space: -o-pre-wrap;
			word-wrap: break-word;

	&__section-divider
		border: none
		height: 0.15rem
		color: $light-purple
		background-color: $light-purple
		margin-top: 2rem
		margin-bottom: 2rem

	&__cta-button--volunteer
		background-color: $white
		color: $purple
		margin-bottom: 6rem

	&__cta-button--donate
		background-color: $purple
		color: $white
		margin-bottom: 1.5rem

	&__cta-alt
		margin-bottom: 8rem

		& a
			color: $light-purple

	&__press-list
		list-style-type: none
		padding-left: 0
		columns: 2
		-webkit-columns: 2
		-moz-columns: 2

	&__press-items

		& a
			display: block
			color: $white
			margin-bottom: 2rem

	&__content-section

		&--donate
			margin-top: 8rem

@media (min-width $pxToRem(450px))
	.about-content
			&__headline-header
				font-size: 2.625rem

@media (min-width $pxToRem(800px))
	.about-content

		&__headline__content-boundary
			max-width: 70rem
			margin-left: auto
			margin-right: auto

			& .about-content__headline-header
				font-size: $pxToRem(72)
				margin-top: -12rem
				max-width 35rem

		&__content-section
			max-width: 70rem
			margin-left: auto
			margin-right: auto

			&--donate
				margin-top: 12rem

</style>
