// Establishing a pattern here, a little over the top possibly
import initCandidates from "./candidates";
import initAdmin from "./admin";
import initTopics from "./topics";
import initHome from "./home";
import initCopy from "./copy";

function makeFirebaseAPI(config) {
	const candidatesAPI = initCandidates(config);
	const adminAPI = initAdmin(config);
	const topicsAPI = initTopics(config);
	const homeAPI = initHome(config);
	const copyAPI = initCopy(config);

	// Example object: `{ candidates: { fetchByZipCode: () => ({}) } }`

	const API = {
		[candidatesAPI.name]: candidatesAPI,
		[adminAPI.name]: adminAPI,
		[topicsAPI.name]: topicsAPI,
		[homeAPI.name]: homeAPI,
		[copyAPI.name]: copyAPI,
		main: {
			getConfig() {
				return config;
			},
		},
	};

	return async function(queryPath, data) {
		// Example queryPath: 'candidates/fetchByZipCode'

		// This is just a routing / merging of other modules.
		// Basically, it just facilitates splitting logic into modules.
		const [module, method] = queryPath.split("/");
		return API[module][method](data);
	};
};

export default makeFirebaseAPI;
