<template>
	<div class="zipSearch-container">
		<div class="zipSearch-bar">
			<input class="zipSearch-input body-l" v-model="zipInput" :placeholder="$t(`components.common.zipSearch.example`)" />
			<button class="zipSearch-submit headline-xs button--link" @click="zipSearchSubmit">{{ $t("components.common.zipSearch.search") }}</button>
		</div>
	</div>
</template>

<script>
export default {
	name: "zip-search",
	data() {
		return {
			zipInput: "",
		};
	},
	computed: {
	},
	methods: {
		zipSearchSubmit() {
			if (this.zipInput) {
				this.$emit("zipSearchSubmit", this.zipInput);
			}
		},
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';

.zipSearch-container
	display: inline-block

.zipSearch-bar
	background-color: $white
	display: flex
	align-items: center
	border-radius: $wrapperBorderRadius
	padding: 0.5rem

.zipSearch-input
	color: $gray
	margin-left: 0.5rem
	margin-right: 0.5rem
	border: none
	width: 12rem
	max-width: 45vw

	&:focus
		outline: none

.zipSearch-submit
	background-color: $purple
	color: $white
	padding: 0.5rem 1.5rem 0.5rem 1.5rem
	border-radius: $btnBorderRadius
	cursor: pointer
</style>
