<template>
	<div class="event-card__container">
		<div class="event-card__image-frame">
			<!--
			<object class="event-card__image" :data="data.eventImage" type="image/png">
				<img class="event-card__image" src="@/assets/fallback-image.jpg" :alt="title" />
			</object>
			-->
			<img class="event-card__image" @error="onImgErr" :src="data.eventImage" :alt="title" />
		</div>
		<div class="event-card__content-block">
			<h4 class="event-card__date headline-xs">{{`${getDay(data.date)}, ${data.date} ${data.startTime} ${data.timeZone}`}}</h4>
			<h3 class="event-card__title headline-l">{{title}}</h3>
			<!--<div class="event-card__description body-s">{{eventDescription}}</div>-->
			<a class="event-card__link headline-xs" target="_blank"  v-if="data.link && data.link !== ''" :href="data.link">{{ $t("components.home.eventCard.eventbriteLink") }}</a>
		</div>
		<hr class="event-card__divider" />
	</div>
</template>

<script>
export default {
	name: "event-card",
	props: {
		title: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
	},
	computed: {
	},
	methods: {
		getDay(dateString) {
			const dayMap = [
				"Sun",
				"Mon",
				"Tue",
				"Wed",
				"Thu",
				"Fri",
				"Sat",
			];
			return dayMap[new Date(dateString).getDay()];
		},
		onImgErr(event) {
			const srcBits = event.target.src.split("/");

			if (srcBits[srcBits.length - 1] !== "fallback-image.jpg") {
				// eslint-disable-next-line no-param-reassign, global-require
				event.target.src = require("../../../assets/fallback-image.jpg");
			}
		},
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';
.event-card

	&__image
		background-color: $gray
		width: 24rem
		max-width: 90vw
		height: auto
		vertical-align: top

	&__fallback
		background-color: $gray
		width: 24rem
		max-width: 90vw
		height: auto
		vertical-align: top

	&__content-block
		max-width: 40rem

	&__date
		color: $light-purple
		font-size: 1.5rem
		margin-top: 1rem
		margin-bottom: 1rem
		padding-bottom: 0

	&__title
		color: $black
		font-size: 2rem
		margin-top: 0
		margin-bottom: 1rem

	&__description
		color: $black
		margin-bottom: 1rem

	&__link
		color: $black

	&__divider
		border: none;
		height: 0.15rem
		color: $light-purple
		background-color: $light-purple
		margin-top: 3rem

@media (min-width $pxToRem(1000px))
	.event-card
		&__image-frame
			margin-top: 1em
			display: inline-block
			vertical-align: top

		&__content-block
			display: inline-block
			margin-left: 1rem
			max-width: 30rem

</style>
