import Vue from "vue";

export default {
	namespaced: true,
	state: {
		legislations: {
			national: [],
			state: [],
			local: [],
		},
		loadingZip: false,
		loadingNational: false,
		fetchedNational: {},
		// loading: false,
		// selectedLegislation: null,
	},

	mutations: {
		setLoadingZip(state, flag) {
			state.loadingZip = !!flag;
		},

		setLoadingNational(state, flag) {
			state.loadingNational = !!flag;
		},

		setFetchedNational(state, topic, flag) {
			state.fetchedNational[topic] = !!flag;
		},

		setLegislations(state, legislations) {
			state.legislations = {
				national: state.legislations.national,
				state: legislations.state,
				local: legislations.local,
			};
		},

		setNationalLegislations(state, legislations) {
			state.legislations = {
				national: legislations.national,
				state: state.legislations.state,
				local: state.legislations.local,
			};
		},

		setSelectedLegislation(state, legislationData) {
			state.selectedLegislation = legislationData;
		},
	},

	actions: {
		async fetchByZipCode({ commit, state/*, getters, dispatch*/ }, input) {
			if (!state.loadingZip) {
				commit("setZip", input.zipCode, { root: true });
				if (input.zipCode && input.zipCode !== "") {
					commit("setLoadingZip", true);
					const legislations = await Vue.prototype.$fbApi("topics/fetchByZipCode", input);
					commit("setLegislations", legislations);
					commit("setLoadingZip", false);
				} else {
					//clear legislations
					commit("setLegislations", {
						national: [],
						state: [],
						local: [],
					});
				}
			} else {
				// console.log("load already in progress"); //TODO: toast?
			}
		},
		async fetchNational({ commit, state/*, getters, dispatch*/ }, input) {
			const cleanedTopic = input.topic.replace(/\s/g, "").toLowerCase().trim();

			if (!state.loadingNational && !state.fetchedNational[cleanedTopic]) {
				commit("setLoadingNational", true);
				const legislations = await Vue.prototype.$fbApi("topics/fetchNational", input);
				commit("setNationalLegislations", legislations);
				commit("setLoadingNational", false);
				commit("setFetchedNational", cleanedTopic, true);
			} else {
				// console.log("already fetched national, or load already in progress"); //TODO: toast?
			}
		},
	},
};
