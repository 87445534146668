function InitializeFirebaseHome(config) {
	const { db } = config;

	return {
		name: "home",

		fetchEvents() {
			return new Promise((resolve, reject) => {
				db.collection("V2Events").get().then((querySnapshot) => {
					const output = [];
					querySnapshot.forEach((doc) => {
						output.push(doc.data());
					});
					resolve(output);
				}).catch((err) => {
					reject(err);
				});
			});
		},
	};
};

export default InitializeFirebaseHome;
