<template>
	<div class="cosponsors-list">
		<div class="cosponsors-list__card-block">
			<div
				v-for="cosponsor in cosponsors"
				:key="cosponsor.name"
				v-bind="cosponsor"
				class="cosponsors-list__cosponsor-card"
			>
				<div class="cosponsors-list__image-frame">
					<!--
					<object class="cosponsors-list__image" :data="cosponsor.image">
						<img class="cosponsors-list__fallback body-xs" src="@/assets/fallback-image.jpg" :alt="cosponsor.name" />
					</object>
					-->
					<img class="cosponsors-list__image" @error="onImgErr" :src="cosponsor.image" :alt="cosponsor.name"/>
				</div>
				<p class="cosponsors-list__name body-s" > {{ cosponsor.name }} </p>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "cosponsors-list",
	props: {
		cosponsors: {
			type: Object,
			required: true,
		},
	},
	methods: {
		onImgErr(event) {
			const srcBits = event.target.src.split("/");

			if (srcBits[srcBits.length - 1] !== "fallback-image.jpg") {
				// eslint-disable-next-line no-param-reassign, global-require
				event.target.src = require("../../../assets/fallback-image.jpg");
			}
		},
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';

.cosponsors-list
	color: $black

	&__title
		color: $lightest-purple
		margin-bottom $pxToRem(35)

	&__card-block
		display: flex
		justify-content: flex-start
		flex-direction: row
		flex-wrap: wrap

	&__cosponsor-card
		display: flex
		align-items: center
		flex-direction: row
		width: 15rem

	&__image-frame
		width: 3rem
		height: 3rem
		border-radius: 50%
		// box-shadow: 0px 10px 14px #00000026;

	&__image
		width: 3rem
		height: 3rem
		object-fit: cover
		border-radius: 50%

	&__fallback
		width: 3rem
		height: 3rem
		object-fit: cover
		border-radius: 50%

	&__name
		margin-left: 1rem
</style>
