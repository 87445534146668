const Formatter = {};

//TODO: take index and type, and return document key accordingly
Formatter.docKeyGen = (formattedCandidate, index, type) => {
	switch (type) {
		case "V2 Events": {
			return index.toString();
		}
		case "V2 Legislations": {
			return formattedCandidate.billNumber + " - " + formattedCandidate.title;
		}
		case "V2 Candidates": {
			let key = "";

			formattedCandidate.name.split(" ").forEach((namePart) => {
				key += namePart.toLowerCase() + "-";
			});

			key += formattedCandidate.level + "-";

			key += formattedCandidate.party.toLowerCase() + "-";

			formattedCandidate.office.split(" ").forEach((officePart) => {
				key += officePart.toLowerCase() + "-";
			});

			//firebase document IDs cannot have slashes
			key = key.replaceAll("/", "");

			//remove the "-" at the end
			key = key.slice(0, -1);

			return key;
		}
		default: {
			let key = "";

			formattedCandidate.name.split(" ").forEach((namePart) => {
				key += namePart.toLowerCase() + "-";
			});

			key += formattedCandidate.level + "-";

			key += formattedCandidate.partyId.toLowerCase() + "-";

			formattedCandidate.office.split(" ").forEach((officePart) => {
				key += officePart.toLowerCase() + "-";
			});

			//firebase document IDs cannot have slashes
			key = key.replaceAll("/", "");

			//remove the "-" at the end
			key = key.slice(0, -1);

			return key;
		}
	}
};

//takes array of many elements, and returns array of arrays containing n elements or the remainder
Formatter.chunkArray = (arr, n) => {
	const output = [];
	//remove empty rows
	const workingArr = arr.filter((object) => Object.keys(object).length > 0);

	//if larger than n, section off
	while (workingArr.length > n) {
		const deletedItems = workingArr.splice(0, n);
		output.push(deletedItems);
	}

	//add remainder, if length > 0
	if (workingArr.length > 0) {
		output.push(workingArr);
	}

	return output;
};

export default Formatter;
