import Vue from "vue";
import Vuex from "vuex";
import candidates from "./candidates";
import candidate from "./candidate";
import topics from "./topics";
import events from "./events";

Vue.use(Vuex);

// I'm pretty sure the Vue dev tools functionally takes care of this
const simpleLogger = (store) => {
	store.subscribe((/*mutation, state*/) => {
		// console.log("Mutation", mutation);
		// console.log("State", state);
	});
};

export default new Vuex.Store({
	state: {
		auth: null,
		copy: {},
		zipCode: null,
		fetchingCopy: false,
	},
	mutations: {
		authChange(state, { auth }) {
			state.auth = auth;
		},

		setZip(state, zipCode) {
			state.zipCode = zipCode;
		},

		setFetching(state, flag) {
			state.fetchingCopy = !!flag;
		},

		setCopy(state, copy) {
			state.copy = copy;
		},
	},
	actions: {
		authChange(context, auth) {
			context.commit("authChange", { auth });
		},
		async fetchCopy({ commit, state }) {
			if (Object.keys(state.copy).length <= 0 && !state.fetchingCopy) {
				commit("setFetching", true);
				const copy = await Vue.prototype.$fbApi("copy/fetchCopy");
				commit("setCopy", copy);
				commit("setFetching", false);
			} else {
				// console.log("copy already fetched, or in progress");
			}
		},
	},
	modules: {
		events,
		candidates,
		candidate,
		topics,
	},
	plugins: [
		simpleLogger,
	],
});
