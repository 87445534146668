import formatter from "../../utils/formatter";

function InitializeFirebaseAdmin(config) {
	const { db } = config;

	return {
		name: "admin",

		//CSVRead
		//NB: batching is done in the LegacyCSVHandler
		//Input
		//  input.isStaging: e.g. true for candidateStagingRef
		//Output
		//  Promise
		CSVRead(input) {
			let dataRef;

			switch (input.type) {
				case "Legacy Candidates": {
					dataRef = input.isStaging ? db.collection("candidatesStaging") : db.collection("candidates");
					break;
				}
				case "V2 Legislations": {
					dataRef = input.isStaging ? db.collection("V2LegislationsStaging") : db.collection("V2Legislations");
					break;
				}
				case "V2 Candidates": {
					dataRef = input.isStaging ? db.collection("V2CandidatesStaging") : db.collection("V2Candidates");
					break;
				}
				case "V2 Events": {
					dataRef = input.isStaging ? db.collection("V2EventsStaging") : db.collection("V2Events");
					break;
				}
				case "V2 Copy": {
					dataRef = input.isStaging ? db.collection("V2CopyStaging") : db.collection("V2Copy");
					break;
				}
				default: {
					// eslint-disable-next-line no-alert
					alert("Invalid type on firebase read");
					throw new Error("Invalid type on firebase read");
				}
			}

			return new Promise((resolve, reject) => {
				dataRef.get().then((querySnapshot) => {
					resolve(querySnapshot);
				}).catch((err) => {
					reject(err);
				});
			});
		},

		//CSVUpload
		//NB: batching is done in the LegacyCSVHandler
		//Input
		//  input.dataBatch: chucnked batch of maximum 400 items
		//  input.isStaging: e.g. true for candidateStagingRef
		//Output
		//  Promise
		CSVUpload(input) {
			const dbBatch = db.batch();
			let dataRef;

			switch (input.type) {
				case "Legacy Candidates": {
					dataRef = input.isStaging ? db.collection("candidatesStaging") : db.collection("candidates");

					input.dataBatch.forEach((candidate, index) => {
						if (Object.keys(candidate).length > 0) {
							console.log(formatter.docKeyGen(candidate, index, input.type));
							dbBatch.set(
								dataRef.doc(formatter.docKeyGen(candidate, index, input.type)),
								candidate,
								// { merge: true },
							);
						}
					});

					return dbBatch.commit();
				}
				case "V2 Legislations": {
					dataRef = input.isStaging ? db.collection("V2LegislationsStaging") : db.collection("V2Legislations");

					console.log("Batch size:", input.dataBatch.length);

					input.dataBatch.forEach((legislation) => {
						if (Object.keys(legislation).length > 0) {
							console.log(legislation.topic);
							console.log(legislation.id);
							dbBatch.set(
								dataRef
									.doc(legislation.topic)
									.collection("legislations")
									.doc(legislation.id),
								legislation.data,
								// { merge: true },
							);
						}
					});

					return dbBatch.commit();
				}
				case "V2 Candidates": {
					dataRef = input.isStaging ? db.collection("V2CandidatesStaging") : db.collection("V2Candidates");

					input.dataBatch.forEach((candidate) => {
						if (Object.keys(candidate).length > 0) {
							console.log(candidate.id);
							dbBatch.set(
								dataRef.doc(candidate.id),
								candidate.data,
								// { merge: true },
							);
						}
					});

					return dbBatch.commit();
				}
				case "V2 Events": {
					dataRef = input.isStaging ? db.collection("V2EventsStaging") : db.collection("V2Events");

					input.dataBatch.forEach((candidate, index) => {
						if (Object.keys(candidate).length > 0) {
							console.log(formatter.docKeyGen(candidate, index, input.type));
							dbBatch.set(
								dataRef.doc(formatter.docKeyGen(candidate, index, input.type)),
								candidate,
								// { merge: true },
							);
						}
					});

					return dbBatch.commit();
				}
				case "V2 Copy": {
					dataRef = input.isStaging ? db.collection("V2CopyStaging") : db.collection("V2Copy");
					const copyObj = input.dataBatch[0];

					console.log("copyObj: ", copyObj);
					console.log("dataRef.doc('en'): ", dataRef.doc("en"));
					//for each locale
					Object.keys(copyObj).forEach((localeKey) => {
						console.log("localeKey: ", localeKey);
						console.log("copyObj[localeKey]: ", copyObj[localeKey]);
						dbBatch.set(
							dataRef.doc(localeKey),
							copyObj[localeKey],
							// { merge: true },
						);
					});

					return dbBatch.commit();
				}
				default: {
					// eslint-disable-next-line no-alert
					alert("Invalid type on firebase upload");
					throw new Error("Invalid type on firebase upload");
				}
			}
		},

		//CSVDelete
		//NB: batching is done in the LegacyCSVHandler
		//Input
		//  input.dataBatch: chucnked batch of maximum 400 items
		//  input.isStaging: e.g. true for candidateStagingRef
		//Output
		//  Promise
		CSVDelete(input) {
			const dbBatch = db.batch();
			let dataRef;

			switch (input.type) {
				case "Legacy Candidates": {
					dataRef = input.isStaging ? db.collection("candidatesStaging") : db.collection("candidates");
					break;
				}
				case "V2 Legislations": {
					dataRef = input.isStaging ? db.collection("V2LegislationsStaging") : db.collection("V2Legislations");
					break;
				}
				case "V2 Candidates": {
					dataRef = input.isStaging ? db.collection("V2CandidatesStaging") : db.collection("V2Candidates");
					break;
				}
				case "V2 Events": {
					dataRef = input.isStaging ? db.collection("V2EventsStaging") : db.collection("V2Events");
					break;
				}
				case "V2 Copy": {
					// eslint-disable-next-line no-alert
					alert("Cleaning not applicable for copy");
					throw new Error("Cleaning not applicable for copy");
				}
				default: {
					// eslint-disable-next-line no-alert
					alert("Invalid type on firebase delete");
					throw new Error("Invalid type on firebase delete");
				}
			}

			//TODO: handle delete patterns according to each type

			input.dataBatch.forEach((candidate) => {
				dbBatch.delete(dataRef.doc(candidate));
			});

			return dbBatch.commit();
		},
	};
};

export default InitializeFirebaseAdmin;
