<template>
	<div class="home-page page">
		<home-content
			:events="events"
			:copy="copy"
			@zipSearchSubmit="handleZipSearchSubmit"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import HomeContent from "../components/Home/HomeContent/HomeContent.vue";

export default {
	name: "home-view",
	components: {
		HomeContent,
	},
	computed: {
		...mapState("events", [
			"events",
		]),
		copy() {
			return this.$store.state.copy;
		},
	},
	methods: {
		...mapActions("events", {
			fetchEvents: "fetchEvents",
		}),
		handleZipSearchSubmit(zipCode) {
			this.$fbConfig.analytics.logEvent("zipSearch");
			this.$store.dispatch("candidates/fetchByZipCode", zipCode);
			this.$router.push({
				name: "candidates",
			});
		},
	},
	mounted() {
		this.fetchEvents();
		this.$store.dispatch("fetchCopy");
	},
};
</script>

<style lang="stylus" scoped>

</style>
