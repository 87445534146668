<template>
	<div class="candidate-view">
		<candidate-content
			:candidateData="selectedCandidate"
			:candidateUnavailable="candidateUnavailable"
			:copy="copy"
		/>
	</div>
</template>

<script>
import CandidateContent from "../components/Candidate/CandidateContent/CandidateContent.vue";

export default {
	name: "candidate-view",
	components: { CandidateContent },
	computed: {
		selectedCandidate() {
			return this.$store.state.candidates.selectedCandidate;
		},
		candidateUnavailable() {
			return this.$store.state.candidates.candidateUnavailable;
		},
		copy() {
			return this.$store.state.copy;
		},
	},
	mounted() {
		this.$store.dispatch("candidates/selectCandidate", this.$route.params.key);
		this.$store.dispatch("fetchCopy");

		// if (this.candidateUnavailable || !this.$route.params.key) {
		// 	this.$router.push({
		// 		name: "404",
		// 	});
		// }
	},
};
</script>

<style lang="stylus" scoped>

</style>
