<template>
	<div class="not-found-content">
		<header-bar :inverted="false" />
		<div class="not-found-content__section--purple">
			<div class="not-found-content__content-boundary">
				<div class="not-found-content__centering-container">
					<div class="not-found-content__headline">
						<h1 class="not-found-content__header headline-xxl">
							{{ $t("components.notFound.notFoundContent.header") }}
						</h1>
					</div>
					<div class="not-found-content__detail body-l">
						<p v-if="!sponsorView">{{ $t("components.notFound.notFoundContent.notFound") }}</p>
						<p v-if="sponsorView">{{ $t("components.notFound.notFoundContent.notFoundSponsor") }}</p>
						<p>
							<span>{{ $t("components.notFound.notFoundContent.pre-link") }}</span>
							<span class="not-found-content__link" @click="navHome">{{ $t("components.notFound.notFoundContent.here") }}</span>
							<span>{{ $t("components.notFound.notFoundContent.post-link") }}</span>
						</p>
					</div>
				</div>
				<footer-bar class="not-found-content__footer" />
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBar from "../../Common/HeaderBar/HeaderBar.vue";
import FooterBar from "../../Common/FooterBar/FooterBar.vue";

export default {
	name: "not-found-content",
	components: {
		HeaderBar,
		FooterBar,
	},
	props: {
		copy: {
			type: Object,
			default() {
				return {};
			},
		},
		sponsorView: {
			type: Boolean,
			default() {
				return false;
			},
		},
	},
	computed: {
		links() {
			if (this.copy.contact) {
				return this.copy.contact.header;
			} else {
				return this.$t("components.contact.contactContent.links");
			}
		},
	},
	methods: {
		navHome() {
			this.$router.push({
				name: "home",
			});
		},
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';
.not-found-content

	&__section--purple
		color: $white
		overflow: auto
		background-color: $purple
		padding-left: 6%
		padding-right: 6%

	&__centering-container
		display: flex
		justify-content: center
		flex-direction: column
		height: 80vh
		text-align: center

	&__headline
		margin-top: 4rem
		margin-bottom: 2rem
		position: relative
		z-index: 2

	&__link
		text-decoration: underline
		cursor: pointer

</style>
