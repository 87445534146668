<template>
	<div class="about-view">
		<about-content
			:copy="copy"
		/>
	</div>
</template>

<script>
import AboutContent from "../components/About/AboutContent/AboutContent.vue";

export default {
	name: "about-view",
	components: {
		AboutContent,
	},
	computed: {
		copy() {
			return this.$store.state.copy;
		},
	},
	mounted() {
		this.$store.dispatch("fetchCopy");
	},
};
</script>

<style lang="stylus" scoped>

</style>
