<template>
	<div class="candidates-activote-display">
		<header-bar id="candidates-activote-display__header" :inverted="false" style="visibility: hidden" /> <!-- TODO: replace this hacky spacer -->
		<header-bar class="candidates-activote-display__header-bar" :inverted="false" :fixTop="true" />
		<svg class="candidates-activote-display__scroll-top" :class="scrollClass" @click="scrollToTop" xmlns="http://www.w3.org/2000/svg" width="17.719" height="32.895" viewBox="0 0 17.719 32.895">
			<path id="Path_43" data-name="Path 43" d="M37.693-15.176l-8.859-8.859v7.342H4.8v3.035H28.834v7.342Z" transform="translate(24.035 37.693) rotate(-90)" fill="#390e7b"/>
		</svg>
		<div class="candidates-activote-display__section--purple">
			<div class="candidates-activote-display__content-boundary">
				<h1 class="candidates-activote-display__title headline-xxl">
					{{$t("components.candidates.candidatesActiVoteDisplay.title")}}
				</h1>
				<hr class="candidates-activote-display__controls-divider" />
				<div class="candidates-activote-display__content-boundary">
					<iframe
						class="candidates-activote-display__iframe"
						title="ActiVote"
						src="
	https://myactivote.com/?attribution=MyVoteProject&invitationCode=MyVoteProject&showIntro=no&calendarType=elections">
					</iframe>
				</div>
				<footer-bar class="candidates-activote-display__footer" />
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBar from "../../Common/HeaderBar/HeaderBar.vue";
import FooterBar from "../../Common/FooterBar/FooterBar.vue";

export default {
	name: "candidates-activote-display",
	components: {
		HeaderBar,
		FooterBar,
	},
	props: {
		candidates: { //key: national, state, local
			type: Object,
			required: true,
		},
		zipCode: {
			type: String,
			default: "",
		},
		candidateLevel: {
			type: String,
			default: "",
		},
		scrolled: {
			type: Boolean,
			default: false,
		},
		copy: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			// selected: "national",
			officeFilter: "all",
			partyFilter: "all",
		};
	},
	computed: {
		scrollClass() {
			return this.scrolled ? "candidates-activote-display__scrolled" : "";
		},
		filteredCandidates() {
			if (!(this.candidates) || !(this.candidates?.[this.candidateLevel])) { return null; }

			const levelCandidates = this.candidates?.[this.candidateLevel] || {};

			if (this.officeFilter === "all") {
				if (this.partyFilter === "all") {
					return levelCandidates;
				} else {
					const results = {};

					Object.keys(levelCandidates).forEach((office) => {
						Object.keys(levelCandidates?.[office]).forEach((candidateID) => {
							const thisParty = levelCandidates?.[office]?.[candidateID].party;

							if (this.partyFilter === thisParty) {
								if (!results[office]) {
									results[office] = [];
								}
								results[office].push(levelCandidates?.[office]?.[candidateID]);
							}
						});
					});

					return results;
				}
			} else {
				if (this.partyFilter === "all") {
					return { [this.officeFilter]: levelCandidates?.[this.officeFilter] };
				} else {
					const results = {};
					const officeCandidates = levelCandidates?.[this.officeFilter] || {};

					Object.keys(officeCandidates).forEach((candidateID) => {
						const thisParty = officeCandidates?.[candidateID].party;

						if (this.partyFilter === thisParty) {
							if (!results[this.officeFilter]) {
								results[this.officeFilter] = [];
							}
							results[this.officeFilter].push(officeCandidates?.[candidateID]);
						}
					});

					return results;
				}
			}
		},
		offices() {
			return Object.keys(this.candidates?.[this.candidateLevel] || {});
		},
		parties() {
			const parties = [];
			const levelCandidates = this.candidates?.[this.candidateLevel] || {};

			Object.keys(levelCandidates).forEach((office) => {
				Object.keys(levelCandidates[office]).forEach((candidateID) => {
					const thisParty = levelCandidates[office][candidateID].party;

					if (!parties.includes(thisParty)) {
						parties.push(thisParty);
					}
				});
			});

			return parties;
		},
	},
	created() {
		window.addEventListener("scroll", this.handleScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	methods: {
		handleTabClicked(level) {
			// this.selected = level;
			this.officeFilter = "all"; // Reset to avoid weird broken values
			this.partyFilter = "all";
			this.$emit("candidateLevelSet", level);
		},
		handleZipSearchSubmit(zipCode) {
			this.$emit("zipSearchSubmit", zipCode);
		},
		handleScroll() {
			if (
				document.body.scrollTop > 10
				|| document.documentElement.scrollTop > 10
			) {
				this.scrolled = true;
			} else {
				this.scrolled = false;
			}
		},
		scrollToTop() {
			document.getElementById("candidates-activote-display__header").scrollIntoView({ behavior: "smooth" });
		},
	},
	mounted() {
		this.scrollToTop();
	},
};
</script>

<style lang="stylus" scoped>
@import "../../../styles/global.styl"

.candidates-activote-display
	margin-left auto
	margin-right auto

	&__scroll-top
		visibility: none
		opacity: 0
		position: fixed
		right: 2rem
		bottom: 2rem
		background-color: $light-purple
		width: 3rem
		height: 3rem
		padding: 1.5rem
		border-radius: 50%
		transition: all 0.5s

	&__scroll-top.candidates-activote-display__scrolled
		cursor: pointer
		visibility: visible
		opacity: 1
		transition: all 0.5s

	&__header-bar
		z-index: 10

	&__section--purple
		background-color: $purple
		color: $white
		overflow: auto

	&__content-boundary
		max-width: $pxToRem(1400)
		margin-left: auto
		margin-right: auto

	&__title
		padding-left 4%
		padding-right 4%
		margin-bottom: 0

	&__subtitle
		color: $lightest-purple
		padding-left 4%
		padding-right 4%
		margin-top: 0.5rem

	&__zipcode
		color: $white
		text-decoration: underline
		cursor: pointer

	&__content-boundary
		max-width: 85rem
		margin-left: auto
		margin-right: auto

	&__iframe
		border: none
		padding-left: 4%
		padding-right: 4%
		max-width: $pxToRem(1300) - 4%
		margin-left: auto
		margin-right: auto
		margin-bottom: 1rem
		width: -moz-available
		width: -webkit-fill-available
		width: fill-available
		height: $pxToRem(550)

	&__controls
		display flex
		flex-direction: column-reverse
		justify-content: flex-end
		padding-left 4%
		padding-right 4%
		margin-top: 2rem

		& span
			color: $lightest-purple
			display: block
			margin-bottom: 0.5rem

	&__office-select
		-webkit-appearance: none
		width: 100%
		color: $black
		font-size: $pxToRem(18)
		background-color: white
		text-align-last: center
		display: block
		border-radius: $btnBorderRadius
		padding: 0.5rem
		margin-bottom: 1rem

		&-shim
			position: relative
			display: inline-block
			width: 100%

			&::after
				content: "⭣"
				position: absolute
				color: $black
				top: 0.5rem
				right: 1rem

	&__locality-tabs
		margin-top: 2rem

	&__level-display
		padding-left 4%
		padding-right 4%
		margin-bottom: 16rem
		max-width: $pxToRem(1400)
		margin-left: auto
		margin-right: auto

	&__search-display
		max-width: 40rem
		margin-top: 4rem
		margin-bottom: 12rem
		margin-left: auto
		margin-right: auto

	&__controls-divider
		border: none
		height: 0.15rem
		color: $white
		background-color: $white
		margin: 0
		margin-top: 4rem
		margin-bottom: 1rem
		margin-left 4%
		margin-right 4%

	&__footer
		padding-left: 4%
		padding-right: 4%

@media screen and (min-width $pxToRem(700))
	.candidates-activote-display
		&__locality-tabs
			max-width: $pxToRem(250)
		&__iframe
			height: $pxToRem(650)

@media screen and (min-width $pxToRem(1100))
	.candidates-activote-display

		&__controls
			flex-direction: row
			justify-content: flex-end

			& span
				display: inline-block

		&__iframe
			height: $pxToRem(650)

		&__office-select
			display: inline-block
			min-width: 10rem
			margin-left: 0.5rem
			margin-bottom: 0

			&-shim
				width: auto

		&__locality-tabs
			justify-content: space-between
			margin-top: 0
			margin-right: auto

		&__office-filter-controls
			display: inline-block
			margin-left: 2rem

</style>
