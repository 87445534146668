<template>
	<div class="topic-detail">
		<header-bar :inverted="false" id="topic-detail__header" style="visibility: hidden" /> <!-- TODO: replace this hacky spacer -->
		<header-bar :inverted="false" :fixTop="true" />
		<div class="topic-detail__section--purple">
			<button class="topic-detail__back-nav headline-xs button--nav" @click="backNav()">
				⭠ Back
			</button>
			<div class="topic-detail__content-boundary">
				<div class="topic-detail__headline">
					<h1 class="topic-detail__header headline-xxl">
						{{ copy.topics ? copy.topics[topic].title : domainsCopy[topic].title }}
					</h1>
					<p class="topic-detail__overview body-l">
						<pre class="body-l"><div class="link-parsed body-l" v-html="parseLink(copy.topics ? copy.topics[topic].overview : domainsCopy[topic].overview)"></div></pre>
					</p>
				</div>
				<hr class="topic-detail__top-divider"/>
				<div class="topic-detail__domains">
					<div
						class="topic-detail__topic headline-l"
						v-for="subjectArea in subjectAreas"
						:id="subjectArea.title.replace(/\s/g, '').toLowerCase()"
						:key="subjectArea.title"
						v-bind="subjectArea"
					>
						<div class="topic-detail__topic-flex">
							<details
								class="topic-detail__subject-details"
								:id="subjectArea.title.replace(/\s/g, '').toLowerCase() + '-details'"
							>
								<summary class="topic-detail__subject-summary">
									<div class="topic-detail__subject-summary-sleeve">
										<span class="topic-detail__topic-title">{{ subjectArea.title }}</span>
										<span class="topic-detail__arrow--open">⭡</span>
										<span class="topic-detail__arrow--closed">⭣</span>
									</div>
								</summary>
								<div class="topic-detail__subject-fold">
									<p class="topic-detail__subject-fold-overview body-l">
										{{ subjectArea.overview }}
									</p>
									<ul>
										<li
											class="topic-detail__subject-fold-bullet body-l"
											v-for="bullet in filterEmpty(subjectArea.sources)"
											:key="bullet.description"
										>
											<p>
												{{ bullet.description }}
											</p>
											<span>⭢ </span><a
												class="topic-detail__subject-fold-link"
												target="_blank"
												:href="bullet.link"
											>
												{{ $t("components.topics.topicDetail['source-link']") }}
											</a>
										</li>
									</ul>
								</div>
							</details>
						</div>
						<hr class="topic-detail__topic-divider" />
					</div>
				</div>
				<legislations-index-display
					:zipCode="zipCode"
					:legislations="legislations"
					@zipSearchSubmit="handleZipSearchSubmit"
				></legislations-index-display>
				<footer-bar />
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBar from "../../Common/HeaderBar/HeaderBar.vue";
import FooterBar from "../../Common/FooterBar/FooterBar.vue";
import LegislationsIndexDisplay from "../LegislationsIndexDisplay/LegislationsIndexDisplay.vue";

export default {
	name: "topic-detail",
	components: {
		HeaderBar,
		FooterBar,
		LegislationsIndexDisplay,
	},
	props: {
		copy: {
			type: Object,
			default() {
				return {};
			},
		},
		zipCode: {
			type: String,
		},
		legislations: { //key: national, state, local
			type: Object,
			default() {
				return {
					national: null,
					state: null,
					local: null,
				};
			},
		},
		topic: {
			type: String,
			required: true,
		},
	},
	computed: {
		subjectAreas() {
			if (this.copy.topics) {
				const areas = [];
				const fetched = this.copy.topics[this.topic].subjectAreas;

				Object.keys(fetched).forEach((key) => {
					areas.push({
						...fetched[key],
					});
				});
				return areas;
			} else {
				const destination = `components.topics.topicsContent.domains.${this.topic}.subjectAreas`;
				const areas = [];
				const fetched = this.$t(destination);
				Object.keys(fetched).forEach((key) => {
					areas.push(fetched[key]);
				});
				return areas;
			}
		},
		domainsCopy() {
			return this.$t("components.topics.topicsContent.domains");
		},
	},
	methods: {
		backNav() {
			this.$router.back();
		},
		handleZipSearchSubmit(zipCode) {
			this.$emit("zipSearchSubmit", zipCode);
		},
		filterEmpty(sources) {
			const output = [];

			sources.forEach((source) => {
				if (source.description && source.description !== "") {
					output.push(source);
				}
			});

			return output;
		},
		parseLink(copy) {
			return copy.replace(/(?:__|[*#])|\[(.*?)\]\(.*?\)(?:__|[*#])|\[(.+?)]\((.+?)\)/gm, "<a class='link-parsed' href='/#/$3'>$2</a>");
		},
	},
	mounted() {
		const selectedSubject = this.$route.query.selectedSubject?.toLowerCase();

		if (this.topic === "no_match_found") {
			this.$router.push({
				name: "404",
			});
		}

		if (selectedSubject) {
			// document.getElementById(selectedSubject).scrollIntoView({ behavior: "smooth" });
			//NB: scroll with a bit of offset, because the header bar covers the content otherwise
			const yValue = document.getElementById(selectedSubject).getBoundingClientRect().top
				+ window.pageYOffset
				- 100; //offset margin
			window.scrollTo({
				behavior: "smooth",
				top: yValue,
			});
			document.getElementById(selectedSubject + "-details").setAttribute("open", true);
		} else {
			document.getElementById("topic-detail__header").scrollIntoView({ behavior: "smooth" });
		}
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';
.topic-detail
	scroll: smooth
	color: $white

	&__section--purple
		background-color: $purple

	&__content-boundary
		max-width: 70rem
		padding-left: 4%
		padding-right: 4%
		margin-left: auto
		margin-right: auto

	&__back-nav
		color: $lightest-purple
		padding-top: 3rem
		padding-left: 3rem
		cursor: pointer

	&__header
		margin-top: 0
		padding-top: 2rem
		margin-bottom: $pxToRem(40)
		padding-bottom: 0

	&__overview
		margin-top: 0
		padding-top: 0
		padding-bottom: 4rem

		& pre
			white-space: pre-wrap;
			white-space: -moz-pre-wrap;
			white-space: -pre-wrap;
			white-space: -o-pre-wrap;
			word-wrap: break-word;

	&__top-divider
		border: none
		height: 0.15rem
		color: $white
		background-color: $white
		margin: 0
		margin-bottom: 4rem

	&__domains
		margin-bottom: 8rem

	// &__topic-flex

	&__subject-summary
		margin-bottom: 2rem
		list-style: none

		& div
			display: flex
			justify-content: flex-end

		&::-webkit-details-marker
			display: none

		&::marker
			display: none

	&__subject-details

		& summary span.topic-detail__arrow
			&--open
				display: none
			&--closed
				display: inline
		&[open] summary span.topic-detail__arrow
			&--open
				display: inline
			&--closed
				display: none

	&__subject-fold-link
		color: $white

	&__topic-title
		margin-right: auto

	&__topic-divider
		border: none
		height: 0.15rem
		color: $light-purple
		background-color: $light-purple
		margin-top: 2rem
		margin-bottom: 3rem
</style>
