<template>
	<div class="contact-view">
		<contact-content
			:copy="copy"
		/>
	</div>
</template>

<script>
import ContactContent from "../components/Contact/ContactContent/ContactContent.vue";

export default {
	name: "contact-view",
	components: {
		ContactContent,
	},
	computed: {
		copy() {
			return this.$store.state.copy;
		},
	},
	mounted() {
		this.$store.dispatch("fetchCopy");
	},
};
</script>

<style lang="stylus" scoped>

</style>
