import Vue from "vue";
import formatter from "../utils/formatter";

const LegacyCSVHandler = {};

const indexMap = {
	legacy: {
		active: 0,
		createdBy: 1,
		factChecked: 2,
		timeStamp: 3,
		level: 45,
		name: 5,
		district: 6,
		circuit: 7,
		coutny: 8,
		state: 9,
		zipCodes: 10,
		party: 11,
		office: 12,
		website: 13,
		image: 14,
		overview: 15,
		other_policies: 16,
		policies: 17,
		articleTitle1: 18,
		articleLink1: 19,
		articleTitle2: 20,
		articleLink2: 21,
		articleTitle3: 22,
		articleLink3: 23,
		articleTitle4: 24,
		articleLink4: 25,
		articleTitle5: 26,
		articleLink5: 27,
		facebook: 28,
		twitter: 29,
		youtube: 30,
		linkedin: 31,
		instagram: 32,
		incumbent: 33,
		legName1: 34,
		legLink1: 35,
		legName2: 36,
		legLink2: 37,
		legName3: 38,
		legLink3: 39,
		legName4: 40,
		legLink4: 41,
		legName5: 42,
		legLink5: 43,
	},
};

const standardOffices = [
	"Mayor - local",
	"City Council Member - local",
	"Alderman - local",
	"City Commissioner - local",
	"County Commissioner - local",
	"City Attorney - local",
	"City Manager - local",
	"City Treasurer - local",
	"Comptroller - local",
	"School Board Member - local",
	"School Board President - local",
	"Precinct Committee Person - local",
	"County Prosecuting Attorney - local",
	"County Treasurer - local",
	"Clerk of Court - state",
	"State Representative - state",
	"State Assembly Person - state",
	"State Senator - state",
	"Governor - state",
	"State Attorney - state",
	"Lieutenant Governor - state",
	"Secretary of State - state",
	"Attorney General - state",
	"State Supreme Court Justice - state",
	"Treasurer - state",
	"US House of Representative - national",
	"US Senator - national",
	"President - national",
	"Vice President - national",
];

//returns null if it's an address that should be ignored
const detectMissingURL = (input) => {
	if (
		!input
		|| input === ""
	) {
		return "";
	}

	let hostName;

	try {
		hostName = new URL(input).hostname.split(".");
	} catch (err) {
		console.log(input, " - url err", err);
		return null;
	}

	if (
		hostName[hostName.length - 1] === "com"
		&& hostName[hostName.length - 2] === "google"
	) {
		return "";
	} else {
		return input;
	}
};

//returns parsed [office, level] info based on raw office, level input
const officeParser = (office, level) => {
	if (standardOffices.includes(office)) {
		return office.split(" - ");
	} else {
		return [office, level];
	}
};

//checks if the array is long enough to prevent out of range error
const fetchArray = (array, index) => {
	if (array.length >= index) {
		return array[index];
	} else {
		return "";
	}
};

const validateURL = (URL) => {
	//Inherit from legacy uploader
	if (URL === null) { //URL hostname parser marks invalid URL as null
		return false;
	} else {
		return true;
	}

	//TODO: this inherited regex hangs
	// const editedURL = URL.trim().replace(/(\r\n|\n|\r)/gm, "");

	// const pattern = new RegExp(
	// 	"^(https?:\\/\\/)?" // protocol
	// 	+ "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" // domain name
	// 	+ "((\\d{1,3}\\.){3}\\d{1,3}))" // ip (v4) address
	// 	+ "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" //port
	// 	+ "(\\?[;&amp;a-z\\d%_.~+=-]*)?" // query string
	// 	+ "(\\#[-a-z\\d_]*)?$",
	// 	"i",
	// );

	// if (pattern.test(editedURL)) {
	// 	return true;
	// } else {
	// 	return false;
	// }
};

LegacyCSVHandler.legacyCandidateFormatter = (arrayRows) => {
	const outputArray = [];

	arrayRows.forEach((row) => {
		const thisArticles = [];

		//Article 1 if it exists
		if (fetchArray(row, indexMap.legacy.articleTitle1)) {
			thisArticles.push(`[${fetchArray(row, indexMap.legacy.articleTitle1)}](${fetchArray(row, indexMap.legacy.articleLink1)})`);
		}

		//Article 2 if it exists
		if (fetchArray(row, indexMap.legacy.articleTitle2)) {
			thisArticles.push(`[${fetchArray(row, indexMap.legacy.articleTitle2)}](${fetchArray(row, indexMap.legacy.articleLink2)})`);
		}

		//Article 3 if it exists
		if (fetchArray(row, indexMap.legacy.articleTitle3)) {
			thisArticles.push(`[${fetchArray(row, indexMap.legacy.articleTitle3)}](${fetchArray(row, indexMap.legacy.articleLink3)})`);
		}

		//Article 4 if it exists
		if (fetchArray(row, indexMap.legacy.articleTitle4)) {
			thisArticles.push(`[${fetchArray(row, indexMap.legacy.articleTitle4)}](${fetchArray(row, indexMap.legacy.articleLink4)})`);
		}

		//Article 5 if it exists
		if (fetchArray(row, indexMap.legacy.articleTitle5)) {
			thisArticles.push(`[${fetchArray(row, indexMap.legacy.articleTitle5)}](${fetchArray(row, indexMap.legacy.articleLink5)})`);
		}

		const thisLegislation = [];

		//Article 1 if it exists
		if (fetchArray(row, indexMap.legacy.legName1)) {
			thisLegislation.push(`[${fetchArray(row, indexMap.legacy.legName1)}](${fetchArray(row, indexMap.legacy.legLink1)})`);
		}

		//Article 2 if it exists
		if (fetchArray(row, indexMap.legacy.legName2)) {
			thisLegislation.push(`[${fetchArray(row, indexMap.legacy.legName2)}](${fetchArray(row, indexMap.legacy.legLink2)})`);
		}

		//Article 3 if it exists
		if (fetchArray(row, indexMap.legacy.legName3)) {
			thisLegislation.push(`[${fetchArray(row, indexMap.legacy.legName3)}](${fetchArray(row, indexMap.legacy.legLink3)})`);
		}

		//Article 4 if it exists
		if (fetchArray(row, indexMap.legacy.legName4)) {
			thisLegislation.push(`[${fetchArray(row, indexMap.legacy.legName4)}](${fetchArray(row, indexMap.legacy.legLink4)})`);
		}

		//Article 5 if it exists
		if (fetchArray(row, indexMap.legacy.legName5)) {
			thisLegislation.push(`[${fetchArray(row, indexMap.legacy.legName5)}](${fetchArray(row, indexMap.legacy.legLink5)})`);
		}

		//only add if fact checked
		if (fetchArray(row, indexMap.legacy.factChecked) === "Y") {
			outputArray.push({
				active: (fetchArray(row, indexMap.legacy.active) === "T"), //must be active (column AP) and also fact checked (column AR)
				articles: thisArticles,
				circuit: fetchArray(row, indexMap.legacy.circuit),
				county: fetchArray(row, indexMap.legacy.county).trim(),
				createdAt: new Date(fetchArray(row, indexMap.legacy.timeStamp)).toString(),
				createdBy: fetchArray(row, indexMap.legacy.createdBy),
				district: fetchArray(row, indexMap.legacy.district),
				image: detectMissingURL(fetchArray(row, indexMap.legacy.image)),
				incumbent: (fetchArray(row, indexMap.legacy.incumbent) === "Yes"),
				legislation: thisLegislation,
				level: officeParser(fetchArray(row, indexMap.legacy.office), fetchArray(row, indexMap.legacy.level))[1].toLowerCase().trim(),
				name: fetchArray(row, indexMap.legacy.name).trim(),
				office: officeParser(fetchArray(row, indexMap.legacy.office), fetchArray(row, indexMap.legacy.level))[0].toLowerCase().trim(),
				officeOrig: officeParser(fetchArray(row, indexMap.legacy.office), fetchArray(row, indexMap.legacy.level))[0].trim(),
				// other_policies: fetchArray(row, indexMap.legacy.other_policies).replace(/\s/g, "").split(","),
				other_policies: fetchArray(row, indexMap.legacy.other_policies).split(","),
				overview: fetchArray(row, indexMap.legacy.overview), //TODO: current version seems to snip off new lines?
				party: fetchArray(row, indexMap.legacy.party).split(" = ")[1],
				partyId: fetchArray(row, indexMap.legacy.party).split(" = ")[0],
				policies: fetchArray(row, indexMap.legacy.policies).replace(/\s/g, "").split(","),
				social: {
					facebook: detectMissingURL(fetchArray(row, indexMap.legacy.facebook)),
					twitter: detectMissingURL(fetchArray(row, indexMap.legacy.twitter)),
					youtube: detectMissingURL(fetchArray(row, indexMap.legacy.youtube)),
					linkedin: detectMissingURL(fetchArray(row, indexMap.legacy.linkedin)),
					instagram: detectMissingURL(fetchArray(row, indexMap.legacy.instagram)),
				},
				state: fetchArray(row, indexMap.legacy.state).trim(),
				valid: true, //TODO: look into how this is determined
				website: detectMissingURL(fetchArray(row, indexMap.legacy.website)),
				zipcodes: fetchArray(row, indexMap.legacy.zipCodes).replace(/\s/g, "").split(","), //TODO: sometimes the volunteer writes extra string
			});
		} else {
			outputArray.push({});
		}
	});

	console.log(outputArray);
	return outputArray;
};

LegacyCSVHandler.legacyCandidateValidator = (formattedData) => {
	const errorOutput = [];

	let thisError = "";

	for (let i = 0; i < formattedData.length; i++) {
		if (Object.keys(formattedData[i]).length > 0) { //has at least one key, and is not an empty or unchecked row
			if (formattedData[i].name === "") {
				thisError += " Missing name";
			}

			if (formattedData[i].office === "") {
				thisError += " Missing office";
			}

			if (
				formattedData[i].level !== "national"
				&& formattedData[i].level !== "state"
				&& formattedData[i].level !== "local"
			) {
				thisError += " Invalid level";
			}

			if (!validateURL(formattedData[i].image)) {
				thisError += " Invalid image";
			}

			if (!validateURL(formattedData[i].website)) {
				thisError += " Invalid website";
			}

			if (!validateURL(formattedData[i].social.facebook)) {
				thisError += " Invalid facebook";
			}

			if (!validateURL(formattedData[i].social.twitter)) {
				thisError += " Invalid twitter";
			}

			if (!validateURL(formattedData[i].social.instagram)) {
				thisError += " Invalid instagram";
			}

			if (!validateURL(formattedData[i].social.youtube)) {
				thisError += " Invalid youtube";
			}

			if (!validateURL(formattedData[i].social.linkedin)) {
				thisError += " Invalid linkedin";
			}

			if (thisError !== "") {
				thisError = "Validation Error for Row " + (i + 2) + ":" + thisError;

				errorOutput.push(thisError);
				thisError = "";
			}
		}
	}

	return errorOutput;
};

LegacyCSVHandler.legacyCandidateUploader = (formattedData, isStaging) => {
	return new Promise((resolve, reject) => {
		const batches = formatter.chunkArray(formattedData, 200);
		const promises = [];

		batches.forEach((dataBatch) => {
			promises.push(Vue.prototype.$fbApi("admin/CSVUpload", { dataBatch, isStaging, type: "Legacy Candidates" }));
		});

		Promise.all(promises).then((results) => {
			resolve(results);
		}).catch((err) => {
			reject(err);
		});
	});
};

//Remove entries that aren't specified in the formattedData
LegacyCSVHandler.legacyCandidateCleaner = (formattedData, isStaging) => {
	return new Promise((resolve, reject) => {
	//build array of IDs
		const idArray = [];
		const removedCandidates = [];

		formattedData.forEach((candidate) => {
			if (
				Object.keys(candidate).length > 0
				&& candidate.active
			) {
				idArray.push(formatter.docKeyGen(candidate));
			}
		});

		console.log("idArray", idArray);

		//read current candidates
		Vue.prototype.$fbApi("admin/CSVRead", { isStaging, type: "Legacy Candidates" }).then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				console.log("doc.id: ", doc.id);
				if (!idArray.includes(doc.id)) {
					removedCandidates.push(doc.id);
				}
			});

			console.log("pre batches", removedCandidates);
			const batches = formatter.chunkArray(removedCandidates, 400);
			console.log("post batches", batches);
			const promises = [];

			batches.forEach((dataBatch) => {
				promises.push(Vue.prototype.$fbApi("admin/CSVDelete", { dataBatch, isStaging, type: "Legacy Candidates" }));
			});

			Promise.all(promises).then(() => {
				resolve(removedCandidates);
			}).catch((err) => {
				reject(err);
			});
		}).catch((err) => {
			// eslint-disable-next-line no-alert
			alert("Clean error: " + err);
		});
	});
};

LegacyCSVHandler.legacyValidRowCounter = (formattedData) => {
	let count = 0;

	for (let i = 0; i < formattedData.length; i++) {
		if (formattedData[i].active) { //filters both inactive and unchecked (empty) rows
			count++;
		}
	}

	return count;
};

export default LegacyCSVHandler;
