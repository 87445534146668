<template>
	<div class="locality-tabs">
		<button
			class="locality-tabs__tab national headline-s button--link"
			:class="nationalClass"
			@click="() => tabClick('national')"
		>
			{{ $t("components.common.localityTabs.national") }}
		</button>
		<button
			class="locality-tabs__tab state headline-s button--link"
			:class="stateClass"
			@click="() => tabClick('state')"
		>
			{{ $t("components.common.localityTabs.state") }}
		</button>
		<button
			class="locality-tabs__tab local headline-s button--link"
			:class="localClass"
			@click="() => tabClick('local')"
		>
			{{ $t("components.common.localityTabs.local") }}
		</button>
	</div>
</template>

<script>
export default {
	name: "locality-tabs",
	props: {
		selected: {
			type: String,
			required: true,
			validator(val) {
				return val === "national" || val === "state" || val === "local";
			},
		},
	},
	computed: {
		nationalClass() {
			return this.selected === "national" ? "selected" : "";
		},
		stateClass() {
			return this.selected === "state" ? "selected" : "";
		},
		localClass() {
			return this.selected === "local" ? "selected" : "";
		},
	},
	methods: {
		tabClick(level) {
			if (this.selected !== level) {
				this.$emit("tabClicked", level);
			}
		},
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';

.locality-tabs
	display flex
	width: 90vw
	max-width: $pxToRem(475)
	justify-content: space-between

	&__tab
		box-sizing border-box
		user-select none
		cursor pointer
		padding-bottom: 0
		color: $white

		&.selected
			// text-decoration: underline
			border-bottom $pxToRem(2) solid $white
			cursor auto

@media screen and (min-width $pxToRem(1100))
	.locality-tabs
		&__tab
			margin-left: 0
			margin-right: 0
</style>
