<template>
	<div id="home-content-top" class="home-content__container">
		<header-bar class="home-content__header-bar" :inverted="true" style="visibility: hidden" /> <!-- TODO: replace this hacky spacer -->
		<header-bar class="home-content__header-bar" :inverted="true" :fixTop="true" :invertOnScroll="true"/>
		<div class="home-content__section home-content__section--lightPurple">
			<div class="home-content__header-boundary">
				<h1 class="home-content__header headline-xxl">{{ copy.homepage ? copy.homepage.header : $t("components.home.homeContent.header") }}</h1>
				<p class="home-content__search-cta headline-xs">{{ $t("components.home.homeContent.activote-prompt") }}</p>
				<button class="home-content__activote-cta button--cta" @click="pushRoute('candidates')">{{ $t("components.home.homeContent.activote-cta") }}</button>
				<img class="home-content__header-image" src="@/assets/homePreview.png" />
			</div>
		</div>
		<div class="home-content__section home-content__section--purple">
			<div class="home-content__content-boundary">
				<h1 class="home-content__about-headline headline-xl">{{ copy.homepage ? copy.homepage["about-headline"] : $t("components.home.homeContent.about-headline") }}</h1>
				<p class="home-content__about-overview body-l">{{ copy.homepage ? copy.homepage["about-overview"] : $t("components.home.homeContent.about-overview") }}</p>
				<button class="home-content__about-cta button--cta" @click="pushRoute('about')">{{ $t("components.home.homeContent.about-cta") }}</button>
			</div>
		</div>
		<div class="home-content__section home-content__section--white">
			<div id="home-events" class="home-content__content-boundary">
				<event-list class="home-content__event-list" :events="events" />
			</div>
		</div>
		<div class="home-content__section home-content__section--purple">
			<div class="home-content__content-boundary">
				<h1 class="home-content__donate-headline headline-xl">{{ copy.homepage ? copy.homepage["donate-headline"] : $t("components.home.homeContent.donate-headline") }}</h1>
				<p class="home-content__donate-overview body-l">{{ copy.homepage ? copy.homepage["donate-overview"] : $t("components.home.homeContent.donate-overview") }}</p>
				<button class="home-content__donate-cta button--cta" @click="openLink('https://edwardcharlesfoundation.networkforgood.com/projects/222923-myvoteproject')">{{ $t("components.home.homeContent.donate-cta") }}</button>
				<p class="home-content__donate-alt body-s">
					<a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdVf76uJ8DJf3q3Qco4BC_ED1mfoCQDMuZr-q5K55g7RD5y_g/viewform">{{ copy.homepage ? copy.homepage["donate-alt-cta"] : $t("components.home.homeContent.donate-alt-cta") }}</a>
				</p>
			</div>
		</div>
		<footer-bar class="home-content__footer" />
	</div>
</template>

<script>
import HeaderBar from "../../Common/HeaderBar/HeaderBar.vue";
import FooterBar from "../../Common/FooterBar/FooterBar.vue";
import EventList from "../EventList/EventList.vue";

export default {
	name: "home-content",
	components: {
		HeaderBar,
		FooterBar,
		EventList,
	},
	props: {
		events: {
			type: Array,
			required: true,
		},
		copy: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	computed: {
	},
	methods: {
		handleZipSearchSubmit(zipCode) {
			this.$emit("zipSearchSubmit", zipCode);
		},
		pushRoute(destination) {
			this.$router.push({
				name: destination,
			});
		},
		openLink(destination) {
			window.open(destination, "_blank");
		},
	},
	mounted() {
		if (this.$route.hash) {
			const el = document.querySelector(this.$route.hash);
			if (el) {
				const yValue = el.getBoundingClientRect().top
					+ window.pageYOffset
					- 100; //offset margin

				window.scrollTo({
					behavior: "smooth",
					top: yValue,
				});
			} else {
				document.getElementById("home-content-top").scrollIntoView({ behavior: "smooth" });
			}
		}
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';
.home-content
	&__header-bar
		z-index: 5

	&__section
		color: $white
		overflow: auto

		&--lightPurple
			background-color: $light-purple

		&--purple
			background-color: $purple

		&--white
			background-color: $white

	&__header
		margin-top: 5rem
		margin-left: 5vw
		max-width: 20rem

	&__search-cta
		margin-top: 4rem
		margin-left: 5vw

	&__zip-search
		margin-top: 1rem
		margin-bottom: 4rem
		margin-left: 5vw

	&__header-image
		display: none

	&__header-boundary
		position: relative //lets the image be positioned absolute relative to this element
		max-width: $pxToRem(1400px)
		margin-left: auto
		margin-right: auto

	&__about-headline
		margin-top: 7rem
		margin-left: 5vw
		margin-right: 5vw
		margin-bottom: 2rem

	&__about-overview
		margin-top: 1rem
		margin-left: 5vw
		margin-right: 5vw
		margin-bottom: 2rem
		line-height: 150%

	&__about-cta
		background-color: $white
		color: $purple
		margin-top: 1rem
		margin-left: 5vw
		margin-bottom: 7rem

	&__activote-cta
		background-color: $purple
		color: $white
		margin-top: 1rem
		margin-left: 5vw
		margin-bottom: 7rem

	&__event-list
		margin-top: 7rem
		margin-left: 5vw
		margin-right: 5vw
		margin-bottom: 4rem

	&__donate-headline
		margin-left: 5vw
		margin-right: 5vw
		margin-top: 6rem
		margin-bottom: 2rem

	&__donate-overview
		margin-left: 5vw
		margin-right: 5vw
		margin-top: 2rem
		margin-bottom: 2rem

	&__donate-cta
		background-color: $white
		color: $purple
		margin-left: 5vw
		margin-right: 5vw
		margin-top: 2rem
		margin-bottom: 2rem

	&__donate-alt
		margin-left: 5vw
		margin-right: 5vw
		margin-top: 2rem
		margin-bottom: 6rem

		a
			color: $lightest-purple

	&__footer
		padding-left: 5vw
		padding-right: 5vw

@media (min-width $pxToRem(600px))
	.home-content
		&__header-image
			display: block
			position: absolute
			right: 0
			width: 50%
			height: auto
			bottom: 15%

@media (min-width $pxToRem(800px))
	.home-content
		&__header-image
			display: block
			position: absolute
			right: 0
			width: 40%
			height: auto
			bottom: 20%
		&__header
			max-width: 30rem
			margin-top: $pxToRem(210)

		&__content-boundary
			max-width: 70rem
			margin-left: auto
			margin-right: auto

		&__event-list
			margin-bottom: 7rem

@media (min-width $pxToRem(1275px))
	.home-content
		&__header-image
			display: inline-block
			position: absolute
			right: 0
			bottom: 1rem
			height: 46.5rem
			width: auto
</style>
