<template>
	<div class="legislation-card">
		<div class="legislation-card__details">
			<div class="legislation-card__top-info">
				<p class="legislation-card__date body-s" v-if="date">
					{{ date }}
				</p>
				<h2 class="legislation-card__title headline-l">{{title}}</h2>
				<p class="legislation-card__billNumber">
					<a class="legislation-card__billNumber-link" target="_blank" :href="link">{{billNumber}}</a>
				</p>
			</div>
			<hr class="legislation-card__content-divider" />
			<details class="legislation-card__fold-section">
				<summary class="legislation-card__fold-summary">
					<div class="legislation-card__fold-summary-sleeve">
						<span class="legislation-card__fold-section-title headline-m">
							{{ $t("components.topics.legislationCard.summary") }}
						</span>
						<span class="legislation-card__arrow--open headline-m">⭡</span>
						<span class="legislation-card__arrow--closed headline-m">⭣</span>
					</div>
				</summary>
				<p class="legislation-card__fold-below body-l">
					{{ overview }}
				</p>
			</details>
			<hr class="legislation-card__content-divider" />
			<details class="legislation-card__fold-section">
				<summary class="legislation-card__fold-summary">
					<div class="legislation-card__fold-summary-sleeve">
						<span class="legislation-card__fold-section-title--open headline-m">
							{{ Object.keys(sponsors).length }} {{ $t("components.topics.legislationCard.sponsors-count") }}
						</span>
						<span class="legislation-card__fold-section-title--closed headline-m">
							{{ $t("components.topics.legislationCard.sponsors") }}
						</span>
						<span class="legislation-card__arrow--open headline-m">⭡</span>
						<span class="legislation-card__arrow--closed headline-m">⭣</span>
					</div>
				</summary>
				<div class="legislation-card__candidate-container body-l">
					<candidate-card
						class="legislation-card__candidate-card"
						v-for="candidate in sponsors"
						:key="candidate.candidateKey"
						:imageLink="candidate.image"
						:candidateKey="genKey(candidate)"
						:name="candidate.name"
						:district="candidate.district"
						:party="candidate.party"
						:office="candidate.office"
					/>
					<!--<hr
						class="legislation-card__candidate-divider"
						v-for="candidate in sponsors"
						:key="candidate.candidateKey"
					/>-->
				</div>
			</details>
			<hr class="legislation-card__content-divider" />
			<details class="legislation-card__fold-section">
				<summary class="legislation-card__fold-summary">
					<div class="legislation-card__fold-summary-sleeve">
						<span class="legislation-card__fold-section-title--open headline-m">
							{{ Object.keys(cosponsors).length }} {{ $t("components.topics.legislationCard.cosponsors-count") }}
						</span>
						<span class="legislation-card__fold-section-title--closed headline-m">
							{{ $t("components.topics.legislationCard.cosponsors") }}
						</span>
						<span class="legislation-card__arrow--open headline-m">⭡</span>
						<span class="legislation-card__arrow--closed headline-m">⭣</span>
					</div>
				</summary>
				<div class="legislation-card__fold-below body-l">
					<cosponsors-list
						class="legislation-card__cosponsors-list"
						:cosponsors="cosponsors"
					/>
				</div>
			</details>
		</div>
	</div>
</template>

<script>
import CandidateCard from "../../Candidates/CandidateCard/CandidateCard.vue";
import CosponsorsList from "../CosponsorsList/CosponsorsList.vue";

export default {
	name: "legislation-card",
	components: { CandidateCard, CosponsorsList },
	props: {
		billNumber: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		sponsors: {
			type: Object,
			required: true,
		},
		cosponsors: {
			type: Object,
			required: true,
		},
		date: {
			type: String,
			required: true,
		},
		link: {
			type: String,
			required: true,
		},
		overview: {
			type: String,
			required: true,
		},
	},
	methods: {
		onClick() {
			// This could also be an $emit, but I don't think we need to keep components that pure
			this.$router.push({
				name: "legislation-details",
				params: {
					key: this.candidateKey,
				},
			});
		},
		genKey(candidate) {
			let key = "";

			candidate.name.split(" ").forEach((namePart) => {
				key += namePart.toLowerCase() + "-";
			});

			key += candidate.level + "-";

			key += candidate.party.toLowerCase() + "-";

			candidate.office.split(" ").forEach((officePart) => {
				key += officePart.toLowerCase() + "-";
			});

			//firebase document IDs cannot have slashes
			if (key.replaceAll) {
				key = key.replaceAll("/", "");
			} else {
				//Thanks, Safari - https://caniuse.com/?search=replaceAll
				while (key.indexOf("/") !== -1) {
					key = key.replace("/", "");
				}
			}

			//remove the "-" at the end
			key = key.slice(0, -1);

			return key;
		},
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';

.legislation-card
	display: flex
	color: $black
	background-color: $white
	border-radius: $cardBorderRadius

	&__details
		// margin-left: auto
		// margin-right: auto
		margin-left: $pxToRem(40)
		margin-right: $pxToRem(40)
		margin-bottom: 3rem
		width: 100%

	&__top-info
		margin-top: 2rem
		margin-bottom: $pxToRem(40)

	&__title
		font-weight: 400

	&__date
		font-size: $pxToRem(14)
		margin-bottom: 1rem

	&__billNumber
		margin-top: 1rem

	&__billNumber-link
		color: $black

	&__content-divider
		border: none
		height: 0.15rem
		color: $light-purple
		background-color: $light-purple
		margin-top: 2rem
		margin-bottom: 2rem

	&__candidate-divider
		border: none
		height: 0.10rem
		color: $light-purple
		background-color: $light-purple
		margin-top: 2rem
		margin-bottom: 2rem

	&__fold-summary
		list-style: none

		& div
			display: flex
			justfiy-content: flex-end

		&::-webkit-details-marker
			display: none

		&::marker
			display: none

	&__fold-section
		cursor: pointer
		& summary span.legislation-card__arrow
			&--open
				display: none
			&--closed
				display: inline
		& summary span.legislation-card__fold-section-title
			&--open
				display: none
			&--closed
				display: inline
		&[open] summary span.legislation-card__arrow
			&--open
				display: inline
			&--closed
				display: none
		&[open] summary span.legislation-card__fold-section-title
			&--open
				display: inline
			&--closed
				display: none

	&__fold-section-title
		margin-right: auto
		&--open
			margin-right: auto
		&--closed
			margin-right: auto

	&__fold-below
		cursor: auto
		margin-top: 2rem

	&__candidate-container
		display: flex
		flex-direction: column

	&__candidate-card
		max-width: 20rem
		padding-top: 1rem
		padding-bottom: 1rem
		margin-top: 2rem
		margin-right: 1rem
		border-color: $lightest-purple
		border-style: solid
		border-width: $pxToRem(1)

@media screen and (min-width $pxToRem(475))
	.legislation-card
		&__candidate-card
			padding-left: 1rem

@media screen and (min-width $pxToRem(800))
	.legislation-card
		&__candidate-container
			flex-direction: row
</style>
