<template>
	<div class="topics-view">
		<topics-content
			:copy="copy"
		/>
	</div>
</template>

<script>
import TopicsContent from "../components/Topics/TopicsContent/TopicsContent.vue";

export default {
	name: "topics-view",
	components: {
		TopicsContent,
	},
	computed: {
		copy() {
			return this.$store.state.copy;
		},
	},
	mounted() {
		this.$store.dispatch("fetchCopy");
	},
};
</script>

<style lang="stylus" scoped>

</style>
