<template>
	<div class="candidate-card" @click="onClick">
		<!-- Image/left content -->
		<div class="candidate-card__left">
			<div class="candidate-card__image-frame">
				<!--
				<object class="candidate-card__image" :data="imageLink" type="image/png">
					<img class="candidate-card__fallback" src="@/assets/fallback-image.jpg" :alt="name" />
				</object>
				-->
				<img class="candidate-card__image" @error="onImgErr" :src="imageLink" :alt="name" />
			</div>
		</div>

		<!-- Right content -->
		<div class="candidate-card__details">
			<div class="candidate-card__top-info">
				<span class="candidate-card__district" v-if="district">
					{{$t("components.candidates.candidateCard.district", { district })}}
				</span>
				<span class="candidate-card__district" v-if="!district">
				</span>
				<span class="candidate-card__party">{{party}}</span>
			</div>

			<div class="candidate-card__mid-info">
				<span class="candidate-card__name headline-s">{{name}}</span>
			</div>

			<div class="candidate-card__bottom-info">
				<span class="candidate-card__view">{{$t("components.candidates.candidateCard.view")}}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "candidate-card",
	props: {
		// NOTE: No zip codes listed here, unnecessary at this level
		imageLink: {
			type: String,
			required: true,
		},
		candidateKey: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			required: true,
		},
		district: {
			type: String,
			required: true,
		},
		party: {
			type: String,
			required: true,
		},
		office: {
			type: String,
			required: true,
		},
	},
	methods: {
		onClick() {
			// This could also be an $emit, but I don't think we need to keep components that pure
			this.$router.push({
				name: "candidate-details",
				params: {
					key: this.candidateKey,
				},
			});
		},
		onImgErr(event) {
			const srcBits = event.target.src.split("/");

			if (srcBits[srcBits.length - 1] !== "fallback-image.jpg") {
				// eslint-disable-next-line no-param-reassign, global-require
				event.target.src = require("../../../assets/fallback-image.jpg");
			}
		},
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';

.candidate-card
	display flex
	// max-width $pxToRem(400)
	height $pxToRem(120)
	background-color $lightgray
	border-radius $cardBorderRadius
	cursor pointer

	&__left
		display flex
		justify-content center
		margin-left $pxToRem(10)
		margin-right $pxToRem(10)

	&__fallback
		object-fit cover
		object-position top
		border-radius 50%
		height $pxToRem(50)
		width $pxToRem(50)
		margin-top $pxToRem(20)

	&__image
		object-fit cover
		object-position top
		border-radius 50%
		height $pxToRem(50)
		width $pxToRem(50)
		margin-top $pxToRem(20)

	&__details
		padding $pxToRem(5)
		padding-top $pxToRem(20)
		padding-bottom $pxToRem(10)
		width 100%
		display flex
		flex-direction column
		justify-content space-around

	&__top-info
		display flex
		justify-content space-between

	&__district
		color: $gray
		font-size $pxToRem(12)

	&__party
		font-weight bold
		color $gray
		font-size $pxToRem(12)
		margin-left $pxToRem(5)

	&__mid-info
		margin-top $pxToRem(5)
		margin-bottom $pxToRem(5)

	// &__name
	// 	font-weight bold
	// 	font-size $pxToRem(22)

	&__view
		font-size $pxToRem(14)
		text-decoration: underline

	// This is excessive, but demonstrates a smoother approach to responsive design
	// Also note, these might need to be tweaked as the context for the component
	// (within the application) changes (e.g. padding in the display page)
	@media screen and (min-width $pxToRem(375))
		.candidate-card
			&__details
				padding $pxToRem(20)

	@media screen and (min-width $pxToRem(425))
		.candidate-card
			&__image
				width $pxToRem(75)
				height $pxToRem(75)

			&__fallback
				width $pxToRem(75)
				height $pxToRem(75)

	@media screen and (min-width $pxToRem(465))
		.candidate-card
			&__district
				font-size $pxToRem(14)

			&__view
				font-size $pxToRem(14)
				margin-right $pxToRem(10)

			// &__name
			// 	font-size $pxToRem(26)

			&__party
				font-size $pxToRem(14)
</style>
