<template>
	<div id="contact-content-top" class="contact-content">
		<header-bar class="contact-content__header-bar" :inverted="false" style="visibility: hidden" /> <!-- TODO: replace this hacky spacer -->
		<header-bar class="contact-content__header-bar" :inverted="false" :fixTop="true" />
		<div class="contact-content__section--purple">
			<div class="contact-content__content-boundary">
				<img class="contact-content__image" src="@/assets/contact-phone.png" />
				<div class="contact-content__headline">
					<h1 class="contact-content__header headline-xxl">
						{{ copy.contact ? copy.contact.header : $t("components.contact.contactContent.header") }}
					</h1>
				</div>
				<div class="contact-content__contacts">
					<div
						class="contact-content__contact"
						v-for="link in links"
						:key="link.title"
					>
						<p class="body-l">{{link.title}}</p>
						<p class="contact-content__contact--link headline-xs"><a :href="link.link">{{link.link.split(":")[1]}}</a></p>
					</div>
				</div>
				<footer-bar class="contact-content__footer" />
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBar from "../../Common/HeaderBar/HeaderBar.vue";
import FooterBar from "../../Common/FooterBar/FooterBar.vue";

export default {
	name: "contact-content",
	components: {
		HeaderBar,
		FooterBar,
	},
	props: {
		copy: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	computed: {
		links() {
			if (this.copy.contact) {
				return this.copy.contact.links;
			} else {
				return this.$t("components.contact.contactContent.links");
			}
		},
	},
	methods: {
		backnav() {
			this.$router.back();
		},
	},
	mounted() {
		document.getElementById("contact-content-top").scrollIntoView({ behavior: "smooth" });
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';
.contact-content

	&__header-bar
		z-index: 100

	&__content-boundary
		position: relative
		max-width: 70rem
		margin-left: auto
		margin-right: auto

	&__image
		position: absolute
		width: 50%
		top: 0
		right: 0
		z-index: 1

	&__fallback
		position: absolute
		width: 75%
		top: 6rem
		right: 4%
		z-index: 1

	&__section--purple
		color: $white
		overflow: auto
		background-color: $purple
		padding-left: 6%
		padding-right: 6%

	&__headline
		margin-top: $pxToRem(80)
		margin-bottom: $pxToRem(40)
		position: relative
		z-index: 2

	&__contacts
		margin-bottom: 12rem
		position: relative
		z-index: 2

	&__contact--link
		font-weight: 500
		& a
			color: $white

	&__contact--link::before
		font-size: 0
		opacity: 0
		content: ""
		transition: all 0.4s

	&__contact--link:hover::before
		font-size: $pxToRem(21)
		opacity: 1
		content: "⭢"
		transition: all 0.4s

@media (min-width $pxToRem(1000px))
	.contact-content

		&__section--purple
			padding-left: 10%
			padding-right: 10%

		&__image
			position: absolute
			width: 45%
			top: 0
			right: 0
			z-index: 1

		&__fallback
			position: absolute
			width: 60%
			max-width: 50vh
			top: -2rem
			right: 6%
			z-index: 1

		&__contact--link
			& a
				font-size: $pxToRem(42)

		&__contact--link:hover::before
			font-size: $pxToRem(42)
</style>
