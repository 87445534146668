import firebase from "firebase";
import * as firebaseui from "firebaseui";
import makeFirebaseAPI from "./firebaseModules/index";

// https://vuejs.org/v2/guide/plugins.html - param reassign is explicitly intended
/* eslint-disable no-param-reassign */
const firebasePlugin = {
	install(Vue /*, options */) {
		// `install` is called on `Vue.use(...)`

		// Initialization config for firebase.
		const config = {};
		config.app = firebase.initializeApp({
			apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
			authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
			databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
			projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
			storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
			messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
			appId: process.env.VUE_APP_FIREBASE_APP_ID,
			measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
		});
		config.ui = new firebaseui.auth.AuthUI(firebase.auth());
		config.uiOptions = {
			signInSuccessUrl: "/#/Admin",
			signInOptions: [
				firebase.auth.GoogleAuthProvider.PROVIDER_ID,
			],
		};
		config.auth = firebase.auth();
		config.analytics = firebase.analytics();

		// if (!firebase.auth().currentUser) { false even if logged in with google. seems to be async.
		config.auth.signInAnonymously().then(() => {
			console.log("FBAA Complete");
		});
		// }

		config.db = firebase.firestore();

		const fbApi = makeFirebaseAPI(config);
		// Attach methods
		Vue.prototype.$fbApi = fbApi;
		Vue.prototype.$fbConfig = config;
	},
};

export default firebasePlugin;
