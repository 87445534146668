<template>
	<div class="topic-view">
		<topic-detail
			:zipCode="zipCode"
			:legislations="legislations"
			:topic="topic"
			:copy="copy"
			@zipSearchSubmit="handleZipSearchSubmit"
		/>
	</div>
</template>

<script>
import TopicDetail from "../components/Topics/TopicDetail/TopicDetail.vue";

export default {
	name: "topics-view",
	components: {
		TopicDetail,
	},
	computed: {
		copy() {
			return this.$store.state.copy;
		},
		zipCode() {
			return this.$store.state.zipCode;
		},
		legislations() {
			return this.$store.state.topics.legislations;
		},
		topic() {
			return this.$route.params.key.replace(/\s/g, "").toLowerCase();
		},
	},
	methods: {
		handleZipSearchSubmit(zipCode) {
			this.$fbConfig.analytics.logEvent("zipSearch");
			this.$store.dispatch("topics/fetchByZipCode", {
				topic: this.$route.params.key,
				zipCode,
			});
		},
	},
	mounted() {
		if (
			this.zipCode
			&& this.zipCode !== ""
		) {
			this.$store.dispatch("topics/fetchByZipCode", {
				topic: this.$route.params.key,
				zipCode: this.zipCode,
			});
		}

		this.$store.dispatch("topics/fetchNational", {
			topic: this.$route.params.key,
		});

		this.$store.dispatch("fetchCopy");
	},
};
</script>

<style lang="stylus" scoped>

</style>
