<template>
	<div id="candidate-content__scroll-top">
		<not-found-content v-if="!candidateData" :sponsorView="true">
		</not-found-content>
		<div v-if="candidateData" class="candidate-content">
			<header-bar class="candidate-content__header-bar" :inverted="false" style="visibility: hidden" /> <!-- TODO: replace this hacky spacer -->
			<header-bar class="candidate-content__header-bar" :inverted="false" :fixTop="true" />
			<div class="candidate-content__section--purple">
				<button class="candidate-content__back-nav headline-xs button--nav" @click="backNav()">
					⭠ Back
				</button>
				<div class="candidate-content__content-boundary">
					<div class="candidate-content__headline">
						<div class="candidate-content__header-details">
							<h1 class="candidate-content__name headline-xxl">
								{{ candidateData.name }}
							</h1>
							<h2 class="candidate-content__office headline-m">
								Candidate for
								<span class="candidate-content__office-title">
									{{ candidateData.office }}
								</span>
							</h2>
							<p class="candidate-content__misc body-l">
								<span class="candidate-content__party">
									{{ candidateData.party }}
								</span>
								<span class="candidate-content__district-divider" v-if="candidateData.district">|</span>
								<span class="candidate-content__district" v-if="candidateData.district">
									District {{ candidateData.district }}
								</span>
							</p>
							<div class="candidate-content__social">
								<svg class="candidate-content__social-icon" v-if="candidateData.link" @click="openLink(candidateData.link)" xmlns="http://www.w3.org/2000/svg" width="18" height="15.75" viewBox="0 0 18 15.75">
									<path id="Path_39" data-name="Path 39" d="M16.312-14.625H1.687A1.688,1.688,0,0,0,0-12.937V-.562A1.688,1.688,0,0,0,1.687,1.125H16.312A1.688,1.688,0,0,0,18-.562V-12.937A1.688,1.688,0,0,0,16.312-14.625Zm0,13.852a.212.212,0,0,1-.211.211H1.9a.212.212,0,0,1-.211-.211V-9H16.312Z" transform="translate(0 14.625)" fill="#c8b9df"/>
								</svg>
								<svg class="candidate-content__social-icon" v-if="candidateData.social.facebook" @click="openLink(candidateData.social.facebook)" xmlns="http://www.w3.org/2000/svg" width="17.437" height="17.332" viewBox="0 0 17.437 17.332">
									<path id="Path_41" data-name="Path 41" d="M17.719-6.75A8.717,8.717,0,0,0,9-15.469,8.717,8.717,0,0,0,.281-6.75,8.722,8.722,0,0,0,7.638,1.863V-4.23H5.423V-6.75H7.638V-8.671a3.076,3.076,0,0,1,3.293-3.392,13.419,13.419,0,0,1,1.952.17v2.145h-1.1a1.26,1.26,0,0,0-1.421,1.362V-6.75H12.78l-.387,2.52H10.362V1.863A8.722,8.722,0,0,0,17.719-6.75Z" transform="translate(-0.281 15.469)" fill="#c8b9df"/>
								</svg>
								<svg class="candidate-content__social-icon" v-if="candidateData.social.instagram" @click="openLink(candidateData.social.instagram)" xmlns="http://www.w3.org/2000/svg" width="15.759" height="15.755" viewBox="0 0 15.759 15.755">
									<path id="Path_42" data-name="Path 42" d="M7.878-10.793A4.033,4.033,0,0,0,3.839-6.753,4.033,4.033,0,0,0,7.878-2.714a4.033,4.033,0,0,0,4.039-4.039A4.033,4.033,0,0,0,7.878-10.793Zm0,6.666A2.631,2.631,0,0,1,5.252-6.753,2.629,2.629,0,0,1,7.878-9.38,2.629,2.629,0,0,1,10.5-6.753,2.631,2.631,0,0,1,7.878-4.127Zm5.147-6.831a.942.942,0,0,0-.942-.942.942.942,0,0,0-.942.942.94.94,0,0,0,.942.942A.94.94,0,0,0,13.025-10.958ZM15.7-10a4.663,4.663,0,0,0-1.273-3.3,4.693,4.693,0,0,0-3.3-1.273c-1.3-.074-5.2-.074-6.5,0a4.686,4.686,0,0,0-3.3,1.269,4.678,4.678,0,0,0-1.273,3.3c-.074,1.3-.074,5.2,0,6.5A4.663,4.663,0,0,0,1.325-.2a4.7,4.7,0,0,0,3.3,1.273c1.3.074,5.2.074,6.5,0A4.663,4.663,0,0,0,14.428-.2a4.693,4.693,0,0,0,1.273-3.3C15.775-4.806,15.775-8.7,15.7-10ZM14.02-2.109a2.659,2.659,0,0,1-1.5,1.5A17.363,17.363,0,0,1,7.878-.3,17.5,17.5,0,0,1,3.234-.612a2.659,2.659,0,0,1-1.5-1.5A17.363,17.363,0,0,1,1.42-6.753,17.5,17.5,0,0,1,1.737-11.4a2.659,2.659,0,0,1,1.5-1.5,17.363,17.363,0,0,1,4.644-.316,17.5,17.5,0,0,1,4.644.316,2.659,2.659,0,0,1,1.5,1.5,17.363,17.363,0,0,1,.316,4.644C14.337-5.607,14.432-3.143,14.02-2.109Z" transform="translate(0.003 14.631)" fill="#c8b9df"/>
								</svg>
								<svg class="candidate-content__social-icon" v-if="candidateData.social.twitter" @click="openLink(candidateData.social.twitter)" xmlns="http://www.w3.org/2000/svg" width="18" height="14.619" viewBox="0 0 18 14.619">
									<path id="Path_40" data-name="Path 40" d="M16.15-10.416A7.93,7.93,0,0,0,18-12.324a7.4,7.4,0,0,1-2.124.571A3.679,3.679,0,0,0,17.5-13.785a7.263,7.263,0,0,1-2.341.891,3.68,3.68,0,0,0-2.7-1.165,3.687,3.687,0,0,0-3.689,3.689,4.164,4.164,0,0,0,.091.845,10.486,10.486,0,0,1-7.607-3.86,3.661,3.661,0,0,0-.5,1.862A3.686,3.686,0,0,0,2.4-8.452,3.715,3.715,0,0,1,.731-8.92v.046A3.69,3.69,0,0,0,3.689-5.254a3.9,3.9,0,0,1-.971.126,4.65,4.65,0,0,1-.7-.057A3.7,3.7,0,0,0,5.471-2.627,7.388,7.388,0,0,1,.891-1.051,7.635,7.635,0,0,1,0-1.1,10.425,10.425,0,0,0,5.665.56a10.424,10.424,0,0,0,10.5-10.5C16.161-10.1,16.161-10.256,16.15-10.416Z" transform="translate(0 14.06)" fill="#c8b9df"/>
								</svg>
								<svg class="candidate-content__social-icon" v-if="candidateData.social.youtube" @click="openLink(candidateData.social.youtube)" xmlns="http://www.w3.org/2000/svg" width="44.8" height="31.5" viewBox="0 0 44.8 31.5">
									<path id="Path_44" data-name="Path 44" d="M45.089-26.571a5.629,5.629,0,0,0-3.961-3.986c-3.494-.942-17.5-.942-17.5-.942s-14.009,0-17.5.942a5.629,5.629,0,0,0-3.961,3.986c-.936,3.516-.936,10.853-.936,10.853s0,7.337.936,10.853A5.545,5.545,0,0,0,6.122-.942C9.616,0,23.625,0,23.625,0s14.009,0,17.5-.942a5.545,5.545,0,0,0,3.961-3.923c.936-3.516.936-10.853.936-10.853S46.025-23.055,45.089-26.571ZM19.043-9.057V-22.379l11.709,6.661Z" transform="translate(-1.225 31.5)" fill="#c8b9df"/>
								</svg>
								<svg class="candidate-content__social-icon" v-if="candidateData.social.linkedin" @click="openLink(candidateData.social.linkedin)" xmlns="http://www.w3.org/2000/svg" width="36.75" height="36.75" viewBox="0 0 36.75 36.75">
									<path id="Path_45" data-name="Path 45" d="M34.125-34.125H2.617A2.636,2.636,0,0,0,0-31.475V-.025a2.636,2.636,0,0,0,2.617,2.65H34.125A2.643,2.643,0,0,0,36.75-.025V-31.475A2.643,2.643,0,0,0,34.125-34.125ZM11.107-2.625H5.66V-20.163h5.455V-2.625ZM8.384-22.559a3.16,3.16,0,0,1-3.158-3.158,3.16,3.16,0,0,1,3.158-3.158,3.165,3.165,0,0,1,3.158,3.158A3.154,3.154,0,0,1,8.384-22.559ZM31.525-2.625H26.078v-8.531c0-2.034-.041-4.651-2.83-4.651-2.838,0-3.273,2.215-3.273,4.5v8.679H14.528V-20.163h5.225v2.4h.074a5.737,5.737,0,0,1,5.16-2.83c5.513,0,6.538,3.634,6.538,8.359Z" transform="translate(0 34.125)" fill="#c8b9df"/>
								</svg>
							</div>
						</div>
						<div class="candidate-content__image-frame">
							<!--
							<object class="candidate-content__image" :data="candidateData.imageLink" type="image/png">
								<img class="candidate-content__fallback" src="@/assets/fallback-image.jpg" />
							</object>
							-->
							<img class="candidate-content__image" @error="onImgErr" :src="candidateData.imageLink" :alt="candidateData.name" />
						</div>
					</div>
					<hr class="candidate-content__top-divider" />
					<h2 class="candidate-content__overview-headline headline-l">
						{{ $t("components.candidate.candidateContent.overview-headline") }}
					</h2>
					<p class="candidate-content__overview body-l">
						<pre class="body-l">{{ candidateData.overview }}</pre>
					</p>
					<hr class="candidate-content__content-divider" />
					<h2 class="candidate-content__focus-headline headline-l">
						{{ $t("components.candidate.candidateContent.focus-headline") }}
					</h2>
					<div class="candidate-content__fold body-l">
						<ul>
							<li
								class="candidate-content__focus-item"
								v-for="focusItem in clickableFocusItems"
								:key="focusItem"
								@click="linkFocus(focusItem)"
							>
								{{ focusItem }}
							</li>
							<li
								class="candidate-content__focus-item--other"
								v-for="focusItem in unclickableFocusItems"
								:key="focusItem"
							>
								{{ focusItem }}
							</li>
						</ul>
					</div>
					<hr class="candidate-content__content-divider" />
					<h2 class="candidate-content__news-headline headline-l">
						{{ $t("components.candidate.candidateContent.news-headline") }}
					</h2>
					<div class="candidate-content__news-bound">
						<div class="candidate-content__news-container">
							<div class="candidate-content__news-flex">
								<news-card
									class="candidate-content__news-card"
									v-for="article in candidateData.articles"
									:key="article.title"
									v-bind="article"
								></news-card>
							</div>
						</div>
					</div>
					<hr class="candidate-content__content-divider news-divider" v-if="candidateData.legislations.length > 0"/>
					<div class="candidate-content__news-spacer" v-if="candidateData.legislations.length === 0"></div>
					<h2 class="candidate-content__legislations-headline headline-l" v-if="candidateData.legislations.length > 0">
						{{ $t("components.candidate.candidateContent.legislations-headline") }}
					</h2>
					<div class="candidate-content__legislations-container body-l" v-if="candidateData.legislations.length > 0">
						<ul>
							<li
								class="candidate-content__legislation-item"
								v-for="legislation in candidateData.legislations"
								:key="legislation.title"
							>
								<a
									class="candidate-content__legislation-link"
									target="_blank"
									:href="legislation.link"
								>
									{{ legislation.title }}
								</a>
							</li>
						</ul>
					</div>
					<footer-bar />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBar from "../../Common/HeaderBar/HeaderBar.vue";
import FooterBar from "../../Common/FooterBar/FooterBar.vue";
import NewsCard from "../NewsCard/NewsCard.vue";
import NotFoundContent from "../../NotFound/NotFoundContent/NotFoundContent.vue";

export default {
	name: "candidate-content",
	components: {
		HeaderBar,
		FooterBar,
		NewsCard,
		NotFoundContent,
	},
	props: {
		candidateData: {
			type: Object,
		},
		candidateUnavailable: {
			type: Boolean,
		},
		copy: {
			type: Object,
			required: false,
		},
	},
	computed: {
		clickableFocusItems() {
			const result = [];

			//sort focus area items according to clickability
			this.candidateData.focusAreas.forEach((areaItem) => {
				if (areaItem !== "" && this.checkValidity(areaItem)) {
					result.push(areaItem);
				}
			});

			return result;
		},
		unclickableFocusItems() {
			const result = [];

			//sort focus area items according to clickability
			this.candidateData.focusAreas.forEach((areaItem) => {
				if (areaItem !== "" && !this.checkValidity(areaItem)) {
					result.push(areaItem);
				}
			});

			//sort all other items as unclickable
			this.candidateData.otherFocusAreas.forEach((areaItem) => {
				if (areaItem && areaItem !== "") {
					result.push(areaItem);
				}
			});

			return result;
		},
	},
	methods: {
		backNav() {
			this.$router.back();
		},
		linkFocus(key) {
			//TODO: resolve route for whether this is a topic domain, and if subject area, under which domain.

			let domains = {};
			let paramKey = null;
			let selectedSubject = null;

			if (this.copy && this.copy.topics) {
				domains = this.copy.topics;
			} else {
				domains = this.$t("components.topics.topicsContent.domains");
			}

			let topicMatch = null;
			let isTopicDomain = false;

			Object.keys(domains).forEach((domainKey) => {
				if (domainKey !== "overview") {
					if (
						domainKey.replace(/\s/g, "").toLowerCase().trim()
						=== key.replace(/\s/g, "").toLowerCase().trim()
					) {
						isTopicDomain = true;
					} else {
						Object.keys(domains[domainKey].subjectAreas).forEach((subjectAreaKey) => {
							if (
								subjectAreaKey.replace(/\s/g, "").toLowerCase().trim()
								=== key.replace(/\s/g, "").toLowerCase().trim()
							) {
								topicMatch = domainKey;
								selectedSubject = subjectAreaKey;
							}
						});
					}
				}
			});

			if (isTopicDomain) {
				paramKey = key;
			} else if (topicMatch) {
				paramKey = topicMatch;
			} else {
				//TODO: no match found!
				paramKey = "NO_MATCH_FOUND";

				//don't navigate
			}

			if (paramKey !== "NO_MATCH_FOUND") {
				this.$router.push({
					name: "topic-details",
					params: {
						key: paramKey,
					},
					query: {
						selectedSubject,
					},
				});
			} else {
				//TODO: temporary until each link runs through validator
				//https://trello.com/c/5uxswTEt
				// eslint-disable-next-line no-alert
				alert("NO MATCH FOUND");
			}
		},
		checkValidity(key) {
			let valid = false;
			let domains = {};

			if (this.copy && this.copy.topics) {
				domains = this.copy.topics;
			} else {
				domains = this.$t("components.topics.topicsContent.domains");
			}

			Object.keys(domains).forEach((domainKey) => {
				if (domainKey !== "overview") {
					if (
						domainKey.replace(/\s/g, "").toLowerCase().trim()
						=== key.replace(/\s/g, "").toLowerCase().trim()
					) {
						valid = true;
					} else {
						Object.keys(domains[domainKey].subjectAreas).forEach((subjectAreaKey) => {
							if (
								subjectAreaKey.replace(/\s/g, "").toLowerCase().trim()
								=== key.replace(/\s/g, "").toLowerCase().trim()
							) {
								valid = true;
							}
						});
					}
				}
			});

			return valid;
		},
		openLink(destination) {
			window.open(destination, "_blank");
		},
		getValidItems(areaItems) {
			const result = [];

			if (areaItems) {
				areaItems.forEach((areaItem) => {
					if (areaItem && areaItem !== "") {
						result.push(areaItem);
					}
				});
			}
			return result;
		}, //NB: candidateData.focusAreas from the template passes in a VueJS object?
		onImgErr(event) {
			const srcBits = event.target.src.split("/");

			if (srcBits[srcBits.length - 1] !== "fallback-image.jpg") {
				// eslint-disable-next-line no-param-reassign, global-require
				event.target.src = require("../../../assets/fallback-image.jpg");
			}
		},
	},
	mounted() {
		// document.getElementById("candidate-content__scroll-top").scrollIntoView({ behavior: "smooth" });
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';
.candidate-content

	&__header-bar
		z-index: 100

	&__section--purple
		position: relative
		overflow: hidden
		color: $white
		background-color: $purple
		padding-left: 6%
		padding-right: 6%

	&__content-boundary
		max-width: 70rem
		margin-left: auto
		margin-right: auto

	&__back-nav
		position: absolute
		color: $lightest-purple
		left: 2rem
		padding-top: 2rem
		padding-bottom: 2rem
		cursor: pointer

	&__headline
		display: flex
		flex-direction: column-reverse
		align-items: center
		background-color: $purple
		margin-top: 5.25rem

	&__header-details
		margin-top: 2rem
		margin-right: auto

	&__name
		margin-bottom: 1rem

	&__office
		margin-bottom: 1rem

	&__office-title
		text-decoration: underline

	&__misc
		margin-bottom: 1rem

	&__social-icon
		width: $pxToRem(18)
		height: $pxToRem(18)
		margin-right: 1.5rem
		fill: $light-purple
		color: $light-purple
		cursor: pointer

	&__party
		margin-right: 1rem

	&__district
		margin-left: 1rem

	&__image
		width: 10rem
		height: 10rem
		object-fit: cover
		border-radius: 50%
		border-radius: 50%
		box-shadow: 0px 10px 14px #00000026;

	&__fallback
		width: 10rem
		height: 10rem
		object-fit: cover
		border-radius: 50%

	&__top-divider
		border: none
		height: 0.15rem
		color: $white
		background-color: $white
		margin: 0
		margin-top: 1rem

	&__content-divider
		border: none
		height: 0.15rem
		color: $light-purple
		background-color: $light-purple
		margin-top: 4rem
		margin-bottom: 4rem

		&.news-divider
			margin-top: 20rem

	&__news-spacer
		margin-top: 24rem

	&__overview-headline
		margin-top: 4rem

	&__overview
		margin-top: 3rem

		& pre
			white-space: pre-wrap;
			white-space: -moz-pre-wrap;
			white-space: -pre-wrap;
			white-space: -o-pre-wrap;
			word-wrap: break-word;

	&__focus-headline
		margin-bottom: 4rem

	&__focus-item
		text-decoration: underline
		cursor: pointer

	&__news-headline
		margin-bottom: 4rem

	&__news-bound
		position: absolute
		height: 15rem
		overflow: hidden

	&__news-container
		height: 20rem //overflow hides scroll bar
		white-space: nowrap
		overflow-x: scroll
		-webkit-overflow-scrolling: touch

	&__news-flex
		display: flex

	&__news-card
		display: inline-block
		white-space: normal
		margin-right: 2rem

	&__legislations-headline
		margin-bottom: 3rem

	&__legislations-container
		margin-bottom 8rem

	&__legislation-link
		color: $white

@media screen and (min-width $pxToRem(1200))
	.candidate-content
		&__headline
			flex-direction: row
			justify-content: flex-end

</style>
