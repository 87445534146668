import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "../plugins/firebase";
import Home from "../views/Home.vue";
import Admin from "../views/Admin.vue";
import Candidates from "../views/Candidates.vue";
import Candidate from "../views/Candidate.vue";
import Topics from "../views/Topics.vue";
import Topic from "../views/Topic.vue";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);
Vue.use(firebase);

const routes = [
	{
		path: "/",
		name: "default",
		component: Home,
		meta: { title: "MyVote Project - Home" },
	},
	{
		path: "/home",
		name: "home",
		component: Home,
		meta: { title: "MyVote Project - Home" },
	},
	{
		path: "/candidates",
		name: "candidates",
		component: Candidates,
		meta: { title: "MyVote Project - Candidates" },
	},
	{
		path: "/candidates/:key",
		name: "candidate-details",
		component: Candidate,
		meta: { title: "MyVote Project - Candidate Detail" },
	},
	{
		path: "/topics",
		name: "topics",
		component: Topics,
		meta: { title: "MyVote Project - Topics" },
	},
	{
		path: "/topic/:key",
		name: "topic-details",
		component: Topic,
		meta: { title: "MyVote Project - Topic Detail" },
	},
	{
		path: "/about",
		name: "about",
		component: About,
		meta: { title: "MyVote Project - About" },
	},
	{
		path: "/contact",
		name: "contact",
		component: Contact,
		meta: { title: "MyVote Project - Contact" },
	},
	{
		path: "/admin",
		name: "admin",
		component: Admin,
		meta: { title: "MyVote Project - Admin" },
	},
	// catch the rest as 404
	{
		path: "*",
		name: "404",
		component: NotFound,
		meta: { title: "MyVote Project - Not Found" },
	},
];

const router = new VueRouter({
	// mode: "history",
	base: process.env.BASE_URL,
	routes,
});

const DEFAULT_TITLE = "MyVote Project";
const analytics = Vue.prototype.$fbConfig.analytics;

router.afterEach((to/*, from*/) => {
	// Use next tick to handle router history correctly
	// see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
	Vue.nextTick(() => {
		document.title = to.meta.title || DEFAULT_TITLE;
		// analytics.setCurrentScreen(to.meta.title || DEFAULT_TITLE);
		// analytics.logEvent("screen_view");
		analytics.logEvent("page_view", {
			page_title: to.meta.title || DEFAULT_TITLE,
		});
	});
});

export default router;
