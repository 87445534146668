import Vue from "vue";

export default {
	namespaced: true,
	state: {
		events: [],
		loading: false,
	},

	mutations: {
		// I believe these should generally be synchronous
		setLoading(state, flag) {
			state.loading = !!flag;
		},

		setevents(state, events) {
			state.events = events;
		},
	},

	actions: {
		async fetchEvents({ commit, state/*, getters, dispatch*/ }) {
			if (!state.loading) {
				commit("setLoading", true);
				const events = await Vue.prototype.$fbApi("home/fetchEvents");
				commit("setevents", events);
				commit("setLoading", false);
			} else {
				// console.log("load already in progress");
			}
		},
	},
};
