import Vue from "vue";
import formatter from "../utils/formatter";

const CSVHandler = {};

const indexMap = {
	legislations: {
		active: 0,
		createdBy: 1,
		factChecked: 2,
		level: 3,
		topic: 4,
		billNumber: 5,
		title: 6,
		link: 7,
		date: 8,
		// dateModified: 9, //Unused
		overview: 10,
		sponsors: 11,
		sponsorImages: 12,
		cosponsors: 13,
		cosponsorImages: 14,
		state: 15,
		zipCodes: 16,
	},
	candidates: {
		active: 0,
		createdBy: 1,
		factChecked: 2,
		timeStamp: 3,
		name: 4,
		state: 5,
		office: 6,
		level: 7,
		district: 8,
		county: 47,
		circuit: 48,
		zipCodes: 9,
		party: 10,
		website: 11,
		image: 12,
		overview: 13,
		focusAreas: 14,
		otherFocusAreas: 15,
		articleTitle1: 16,
		articleLink1: 17,
		articleSource1: 37,
		articleTitle2: 18,
		articleLink2: 19,
		articleSource2: 38,
		articleTitle3: 20,
		articleLink3: 21,
		articleSource3: 39,
		articleTitle4: 22,
		articleLink4: 23,
		articleSource4: 40,
		articleTitle5: 24,
		articleLink5: 25,
		articleSource5: 41,
		facebook: 26,
		twitter: 27,
		youtube: 28,
		linkedin: 29,
		instagram: 30,
		incumbent: 31,
		legislationName1: 32,
		legislationName2: 33,
		legislationName3: 34,
		legislationName4: 35,
		legislationName5: 36,
		legislationLink1: 42,
		legislationLink2: 43,
		legislationLink3: 44,
		legislationLink4: 45,
		legislationLink5: 46,
	},
	events: {
		title: 0,
		date: 1,
		earlyVoteDate: 2,
		startTime: 3,
		endTime: 4,
		timeZone: 5,
		link: 6,
		eventImage: 7,
		isWelcomeCall: 8,
		isElection: 9,
		active: 10,
	},
	copy: { //this sheet has axes flipped. specify row numbers
		homepage: {
			header: 2,
			"about-headline": 3,
			"about-overview": 4,
			"donate-headline": 5,
			"donate-overview": 6,
			"donate-alt-cta": 7,
		},
		about: {
			header: 8,
			"story-header": 9,
			"story-overview": 10,
			"mission-header": 11,
			"mission-overview": 12,
			"team-header": 13,
			"team-#-name": 14,
			"team-#-role": 15,
			"team-#-image": 16,
			"volunteer-header": 17,
			"volunteer-overview": 18,
			press: {
				title: 19,
				link: 20,
			},
			"donate-headline": 21,
			"donate-overview": 22,
		},
		topics: {
			overview: 23,
		},
		contact: {
			header: 24,
			title: 25,
			link: 26,
		},
		candidates: {
			cta: 27,
			empty: 28,
		},
		policy: {
			title1: 29,
			"title-1-overview": 30,
			"title-1-subjectAreaTitles": 31,
			"title-1-subjectAreaOverviews": 32,
			"title-1-bullet1": 33,
			"title-1-link1": 34,
			"title-1-bullet2": 35,
			"title-1-link2": 36,
			"title-1-bullet3": 37,
			"title-1-link3": 38,
			"title-1-bullet4": 39,
			"title-1-link4": 40,
			"title-1-bullet5": 41,
			"title-1-link5": 42,
			title2: 43,
			"title-2-overview": 44,
			"title-2-subjectAreaTitles": 45,
			"title-2-subjectAreaOverviews": 46,
			"title-2-bullet1": 47,
			"title-2-link1": 48,
			"title-2-bullet2": 49,
			"title-2-link2": 50,
			"title-2-bullet3": 51,
			"title-2-link3": 52,
			"title-2-bullet4": 53,
			"title-2-link4": 54,
			"title-2-bullet5": 55,
			"title-2-link5": 56,
			title3: 57,
			"title-3-overview": 58,
			"title-3-subjectAreaTitles": 59,
			"title-3-subjectAreaOverviews": 60,
			"title-3-bullet1": 61,
			"title-3-link1": 62,
			"title-3-bullet2": 63,
			"title-3-link2": 64,
			"title-3-bullet3": 65,
			"title-3-link3": 66,
			"title-3-bullet4": 67,
			"title-3-link4": 68,
			"title-3-bullet5": 69,
			"title-3-link5": 70,
			title4: 71,
			"title-4-overview": 72,
			"title-4-subjectAreaTitles": 73,
			"title-4-subjectAreaOverviews": 74,
			"title-4-bullet1": 75,
			"title-4-link1": 76,
			"title-4-bullet2": 77,
			"title-4-link2": 78,
			"title-4-bullet3": 79,
			"title-4-link3": 80,
			"title-4-bullet4": 81,
			"title-4-link4": 82,
			"title-4-bullet5": 83,
			"title-4-link5": 84,
			title5: 85,
			"title-5-overview": 86,
			"title-5-subjectAreaTitles": 87,
			"title-5-subjectAreaOverviews": 88,
			"title-5-bullet1": 89,
			"title-5-link1": 90,
			"title-5-bullet2": 91,
			"title-5-link2": 92,
			"title-5-bullet3": 93,
			"title-5-link3": 94,
			"title-5-bullet4": 95,
			"title-5-link4": 96,
			"title-5-bullet5": 97,
			"title-5-link5": 98,
		},
		offDesc: {
			offDesc_mayorlocal: 99,
			offDesc_citycouncilmemberlocal: 100,
			offDesc_aldermanlocal: 101,
			offDesc_citycommissionerlocal: 102,
			offDesc_countycommissionerlocal: 103,
			offDesc_cityattorneylocal: 104,
			offDesc_citymanagerlocal: 105,
			offDesc_citytreasurerlocal: 106,
			offDesc_comptrollerlocal: 107,
			offDesc_schoolboardmemberlocal: 108,
			offDesc_schoolboardpresidentlocal: 109,
			offDesc_precinctcommitteepersonlocal: 110,
			offDesc_countyprosecutingattorneylocal: 111,
			offDesc_countytreasurerlocal: 112,
			offDesc_clerkofcourtstate: 113,
			offDesc_staterepresentativestate: 114,
			offDesc_stateassemblypersonstate: 115,
			offDesc_statesenatorstate: 116,
			offDesc_governorstate: 117,
			offDesc_stateattorneystate: 118,
			offDesc_lieutenantgovernorstate: 119,
			offDesc_secretaryofstatestate: 120,
			offDesc_attorneygeneralstate: 121,
			offDesc_statesupremecourtjusticestate: 122,
			offDesc_treasrerstate: 123,
			offDesc_ushouserepresentativenational: 124,
			offDesc_ussenatornational: 125,
			offDesc_presidentnational: 126,
			offDesc_vicepresidentnational: 127,
			"otherOffDesc_local-#-title": 128,
			"otherOffDesc_local-#-desc": 129,
			"otherOffDesc_state-#-title": 130,
			"otherOffDesc_state-#-desc": 131,
			"otherOffDesc_national-#-title": 132,
			"otherOffDesc_national-#-desc": 133,
		},
	},
};

const dropDownOptions = {
	office: [
		"Mayor - local",
		"City Council Member - local",
		"Alderman - local",
		"City Commissioner - local",
		"County Commissioner - local",
		"City Attorney - local",
		"City Manager - local",
		"City Treasurer - local",
		"Comptroller - local",
		"School Board Member - local",
		"School Board President - local",
		"Precinct Committee Person - local",
		"County Prosecuting Attorney - local",
		"County Treasurer - local",
		"Clerk of Court - state",
		"State Representative - state",
		"State Assembly Person - state",
		"State Senator - state",
		"Governor - state",
		"State Attorney - state",
		"Lieutenant Governor - state",
		"Secretary of State - state",
		"Attorney General - state",
		"State Supreme Court Justice - state",
		"Treasurer - state",
		"US House Representative - national",
		"US Senator - national",
		"President - national",
		"Vice President - national",
	],
};

//returns null if it's an address that should be ignored
// const detectMissingURL = (input) => {
// 	if (
// 		!input
// 		|| input === ""
// 	) {
// 		return "";
// 	}

// 	let hostName;

// 	try {
// 		hostName = new URL(input).hostname.split(".");
// 	} catch (err) {
// 		console.log(input, " - url err", err);
// 		return null;
// 	}

// 	if (
// 		hostName[hostName.length - 1] === "com"
// 		&& hostName[hostName.length - 2] === "google"
// 	) {
// 		return "";
// 	} else {
// 		return input;
// 	}
// };

//returns parsed [office, level] info based on raw office, level input
const officeParser = (office, level) => {
	if (dropDownOptions.office.includes(office)) {
		return office.split(" - ");
	} else {
		return [office, level];
	}
};

//checks if the array is long enough to prevent out of range error
const fetchArray = (array, index) => {
	if (array.length >= index) {
		return array[index] || "";
	} else {
		return "";
	}
};

// //takes array of many elements, and returns array of arrays containing n elements
// const chunkArray = (arr, n) => {
// 	const output = [];
// 	//remove empty rows
// 	const workingArr = arr.filter((object) => Object.keys(object).length > 0);

// 	//if larger than n, section off
// 	while (workingArr.length > n) {
// 		const deletedItems = workingArr.splice(0, n);
// 		output.push(deletedItems);
// 	}

// 	//add remainder, if length > 0
// 	if (workingArr.length > 0) {
// 		output.push(workingArr);
// 	}

// 	return output;
// };

//legislations are formatted as { level, id, data }
//candidates are formatted as { id, data }
//events are formatted as { title, data }
CSVHandler.dataFormatter = (arrayRows, type) => {
	let outputArray = [];

	arrayRows.forEach((row) => {
		switch (type) {
			case "V2 Legislations": {
				const parsedSponsors = {};
				const parsedCosponsors = {};

				fetchArray(row, indexMap.legislations.sponsors).split("###").forEach((sponsorText, sponsorIndex) => {
					if (sponsorText.split("/").length === 11) {
						const sponsorSplit = sponsorText.split("/");
						let sponsorID = "";
						let sponsorName = "";

						for (let i = 0; i < 5; i++) {
							if (sponsorSplit[i] !== "") {
								sponsorID += (sponsorSplit[i].toLowerCase() + "-");
								sponsorName += (sponsorSplit[i] + " ");
							}
						}

						sponsorID += sponsorSplit[7].toLowerCase() + "-";

						sponsorID += sponsorSplit[5].toLowerCase() + "-";

						sponsorSplit[6].split(" ").forEach((officePart) => {
							sponsorID += officePart.toLowerCase() + "-";
						});

						//firebase document IDs cannot have slashes
						sponsorID = sponsorID.replaceAll("/", "");

						//remove the "-" at the end
						sponsorID = sponsorID.slice(0, -1);
						sponsorName = sponsorName.slice(0, -1);

						parsedSponsors[sponsorID] = {
							name: sponsorName,
							level: sponsorSplit[7].toLowerCase().trim(),
							party: sponsorSplit[5],
							office: sponsorSplit[6],
							state: sponsorSplit[8],
							district: sponsorSplit[9],
							image: fetchArray(row, indexMap.legislations.sponsorImages).split("###")[sponsorIndex] || "",
						};
					} else {
						//format error
						parsedSponsors.formatError = true;
					}
				});

				//cosponsors can be empty
				if (fetchArray(row, indexMap.legislations.cosponsors) !== "") {
					fetchArray(row, indexMap.legislations.cosponsors).split("###").forEach((sponsorText, sponsorIndex) => {
						if (sponsorText.split("/").length === 11) {
							const sponsorSplit = sponsorText.split("/");
							let sponsorID = "";
							let sponsorName = "";

							for (let i = 0; i < 5; i++) {
								if (sponsorSplit[i] !== "") {
									sponsorID += (sponsorSplit[i].toLowerCase() + "-");
									sponsorName += (sponsorSplit[i] + " ");
								}
							}

							sponsorID += sponsorSplit[7].toLowerCase() + "-";

							sponsorID += sponsorSplit[5].toLowerCase() + "-";

							sponsorSplit[6].split(" ").forEach((officePart) => {
								sponsorID += officePart.toLowerCase() + "-";
							});

							//firebase document IDs cannot have slashes
							sponsorID = sponsorID.replaceAll("/", "");

							//remove the "-" at the end
							sponsorID = sponsorID.slice(0, -1);
							sponsorName = sponsorName.slice(0, -1);

							parsedCosponsors[sponsorID] = {
								name: sponsorName,
								level: sponsorSplit[7].toLowerCase().trim(),
								party: sponsorSplit[5],
								office: sponsorSplit[6],
								state: sponsorSplit[8],
								district: sponsorSplit[9],
								image: fetchArray(row, indexMap.legislations.cosponsorImages).split("###")[sponsorIndex] || "",
							};
						} else {
							//format error
							parsedCosponsors.formatError = true;
						}
					});
				}

				if (fetchArray(row, indexMap.legislations.factChecked) === "Y") {
					outputArray.push({
						id: fetchArray(row, indexMap.legislations.billNumber) + " - " + fetchArray(row, indexMap.legislations.title).replaceAll("/", "\\").substring(0, 1000),
						topic: fetchArray(row, indexMap.legislations.topic).replace(/\s/g, "").toLowerCase().trim(),
						data: {
							active: (fetchArray(row, indexMap.legislations.active) === "T"),
							billNumber: fetchArray(row, indexMap.legislations.billNumber),
							title: fetchArray(row, indexMap.legislations.title),
							link: fetchArray(row, indexMap.legislations.link),
							overview: fetchArray(row, indexMap.legislations.overview),
							date: fetchArray(row, indexMap.legislations.date),
							sponsors: parsedSponsors,
							cosponsors: parsedCosponsors,
							state: fetchArray(row, indexMap.legislations.state),
							level: fetchArray(row, indexMap.legislations.level).toLowerCase().trim(),
							zipCodes: fetchArray(row, indexMap.legislations.zipCodes).replace(/\s/g, "").split(","),
						},
					});
				} else {
					outputArray.push({});
				}
				break;
			}
			case "V2 Candidates": {
				const thisArticles = [];

				//Article 1 if it exists
				if (fetchArray(row, indexMap.candidates.articleTitle1) !== "") {
					thisArticles.push({
						title: fetchArray(row, indexMap.candidates.articleTitle1).trim(),
						source: fetchArray(row, indexMap.candidates.articleSource1).trim(),
						link: fetchArray(row, indexMap.candidates.articleLink1).trim(),
					});
				}

				//Article 2 if it exists
				if (fetchArray(row, indexMap.candidates.articleTitle2) !== "") {
					thisArticles.push({
						title: fetchArray(row, indexMap.candidates.articleTitle2).trim(),
						source: fetchArray(row, indexMap.candidates.articleSource2).trim(),
						link: fetchArray(row, indexMap.candidates.articleLink2).trim(),
					});
				}

				//Article 3 if it exists
				if (fetchArray(row, indexMap.candidates.articleTitle3) !== "") {
					thisArticles.push({
						title: fetchArray(row, indexMap.candidates.articleTitle3).trim(),
						source: fetchArray(row, indexMap.candidates.articleSource3).trim(),
						link: fetchArray(row, indexMap.candidates.articleLink3).trim(),
					});
				}

				//Article 4 if it exists
				if (fetchArray(row, indexMap.candidates.articleTitle4) !== "") {
					thisArticles.push({
						title: fetchArray(row, indexMap.candidates.articleTitle4).trim(),
						source: fetchArray(row, indexMap.candidates.articleSource4).trim(),
						link: fetchArray(row, indexMap.candidates.articleLink4).trim(),
					});
				}

				//Article 5 if it exists
				if (fetchArray(row, indexMap.candidates.articleTitle5) !== "") {
					thisArticles.push({
						title: fetchArray(row, indexMap.candidates.articleTitle5).trim(),
						source: fetchArray(row, indexMap.candidates.articleSource5).trim(),
						link: fetchArray(row, indexMap.candidates.articleLink5).trim(),
					});
				}

				const thisLegislations = [];

				//Legislation 1 if it exists
				if (fetchArray(row, indexMap.candidates.legislationName1) !== "") {
					thisLegislations.push({
						billNumber: fetchArray(row, indexMap.candidates.legislationName1).split(" - ")[0] || "",
						title: fetchArray(row, indexMap.candidates.legislationName1).split(" - ")[1] || "",
						link: fetchArray(row, indexMap.candidates.legislationLink1).trim() || "",
					});
				}

				//Legislation 2 if it exists
				if (fetchArray(row, indexMap.candidates.legislationName2) !== "") {
					thisLegislations.push({
						billNumber: fetchArray(row, indexMap.candidates.legislationName2).split(" - ")[0] || "",
						title: fetchArray(row, indexMap.candidates.legislationName2).split(" - ")[1] || "",
						link: fetchArray(row, indexMap.candidates.legislationLink2).trim() || "",
					});
				}

				//Legislation 3 if it exists
				if (fetchArray(row, indexMap.candidates.legislationName3) !== "") {
					thisLegislations.push({
						billNumber: fetchArray(row, indexMap.candidates.legislationName3).split(" - ")[0] || "",
						title: fetchArray(row, indexMap.candidates.legislationName3).split(" - ")[1] || "",
						link: fetchArray(row, indexMap.candidates.legislationLink3).trim() || "",
					});
				}

				//Legislation 4 if it exists
				if (fetchArray(row, indexMap.candidates.legislationName4) !== "") {
					thisLegislations.push({
						billNumber: fetchArray(row, indexMap.candidates.legislationName4).split(" - ")[0] || "",
						title: fetchArray(row, indexMap.candidates.legislationName4).split(" - ")[1] || "",
						link: fetchArray(row, indexMap.candidates.legislationLink4).trim() || "",
					});
				}

				//Legislation 5 if it exists
				if (fetchArray(row, indexMap.candidates.legislationName5) !== "") {
					thisLegislations.push({
						billNumber: fetchArray(row, indexMap.candidates.legislationName5).split(" - ")[0] || "",
						title: fetchArray(row, indexMap.candidates.legislationName5).split(" - ")[1] || "",
						link: fetchArray(row, indexMap.candidates.legislationLink5).trim() || "",
					});
				}

				//Add objects
				if (fetchArray(row, indexMap.candidates.factChecked) === "Y") {
					outputArray.push({
						id: formatter.docKeyGen(
							{
								name: fetchArray(row, indexMap.candidates.name).trim(),
								level: officeParser(fetchArray(row, indexMap.candidates.office), fetchArray(row, indexMap.candidates.level))[1].toLowerCase().trim(),
								party: fetchArray(row, indexMap.candidates.party).split(" = ")[0],
								office: officeParser(fetchArray(row, indexMap.candidates.office), fetchArray(row, indexMap.candidates.level))[0].trim(),
							},
							null,
							"V2 Candidates",
						),
						data: {
							active: (fetchArray(row, indexMap.candidates.active) === "T"),
							valid: true,
							createdAt: new Date(fetchArray(row, indexMap.candidates.timeStamp)).toString(),
							createdBy: fetchArray(row, indexMap.candidates.createdBy),
							circuit: fetchArray(row, indexMap.candidates.circuit).trim(),
							county: fetchArray(row, indexMap.candidates.county).trim(),
							district: fetchArray(row, indexMap.candidates.district),
							incumbent: (fetchArray(row, indexMap.candidates.incumbent) === "Yes"),
							level: officeParser(fetchArray(row, indexMap.candidates.office), fetchArray(row, indexMap.candidates.level))[1].toLowerCase().trim(),
							office: officeParser(fetchArray(row, indexMap.candidates.office), fetchArray(row, indexMap.candidates.level))[0].trim(), //NB: untrimmed, non-lowercase
							name: fetchArray(row, indexMap.candidates.name).trim(),
							party: fetchArray(row, indexMap.candidates.party).split(" = ")[0],
							state: fetchArray(row, indexMap.candidates.state),
							imageLink: fetchArray(row, indexMap.candidates.image).trim(),
							link: fetchArray(row, indexMap.candidates.website).trim(),
							zipCodes: fetchArray(row, indexMap.candidates.zipCodes).replace(/\s/g, "").split(","),
							overview: fetchArray(row, indexMap.candidates.overview),
							articles: thisArticles,
							legislations: thisLegislations,
							focusAreas: fetchArray(row, indexMap.candidates.focusAreas).split(", "),
							otherFocusAreas: fetchArray(row, indexMap.candidates.otherFocusAreas).split(", "),
							social: {
								facebook: fetchArray(row, indexMap.candidates.facebook),
								twitter: fetchArray(row, indexMap.candidates.twitter),
								youtube: fetchArray(row, indexMap.candidates.youtube),
								linkedin: fetchArray(row, indexMap.candidates.linkedin),
								instagram: fetchArray(row, indexMap.candidates.instagram),
							},
						},
					});
				} else {
					outputArray.push({});
				}
				break;
			}
			case "V2 Events": {
				if (fetchArray(row, indexMap.events.active) === "T") {
					outputArray.push({
						title: fetchArray(row, indexMap.events.title).trim(),
						data: {
							active: true,
							date: fetchArray(row, indexMap.events.date),
							earlyVoteDate: fetchArray(row, indexMap.events.earlyVoteDate),
							startTime: fetchArray(row, indexMap.events.startTime),
							eventImage: fetchArray(row, indexMap.events.eventImage),
							endTime: fetchArray(row, indexMap.events.endTime),
							timeZone: fetchArray(row, indexMap.events.timeZone),
							link: fetchArray(row, indexMap.events.link).trim(),
							isWelcomeCall: (fetchArray(row, indexMap.events.link) === "Y"),
							isElection: (fetchArray(row, indexMap.events.link) === "Y"),
						},
					});
				} else {
					outputArray.push({});
				}
				break;
			}
			case "V2 Copy": {
				outputArray.push(row);
				break;
			}
			default: {
				console.error("Invalid type on candidateFormatter", type);
			}
		}
	});

	//Needs additional object structuring outside of the iterable
	if (type === "V2 Copy") {
		//map team mebers to an array
		const teamMembers = [];

		//for each team member name, skipping the first 2 cells (lables)
		for (let i = 2; i < outputArray[indexMap.copy.about["team-#-name"] - 2].length; i++) {
			if (outputArray[indexMap.copy.about["team-#-name"] - 2][i] !== "") {
				teamMembers.push({
					name: outputArray[indexMap.copy.about["team-#-name"] - 2][i] || "",
					image: outputArray[indexMap.copy.about["team-#-image"] - 2][i] || "",
					role: outputArray[indexMap.copy.about["team-#-role"] - 2][i] || "",
				});
			}
		};

		//map press items to an array
		const pressItems = [];
		//for each press title, skipping the first 2 cells (lables)
		for (let i = 2; i < outputArray[indexMap.copy.about.press.title - 2].length; i++) {
			if (outputArray[indexMap.copy.about.press.title - 2][i] !== "") {
				pressItems.push({
					title: outputArray[indexMap.copy.about.press.title - 2][i] || "",
					link: outputArray[indexMap.copy.about.press.link - 2][i] || "",
				});
			}
		};

		//map contact links to an array
		const contactItems = [];
		//for each contact title, skipping the first 2 cells (lables)
		for (let i = 2; i < outputArray[indexMap.copy.contact.title - 2].length; i++) {
			if (outputArray[indexMap.copy.contact.title - 2][i] !== "") {
				contactItems.push({
					title: outputArray[indexMap.copy.contact.title - 2][i] || "",
					link: outputArray[indexMap.copy.contact.link - 2][i] || "",
				});
			}
		};

		//local custom office descriptions
		const localOfficeDescriptions = {};

		const inputLocalTitles = outputArray[indexMap.copy.offDesc["otherOffDesc_local-#-title"] - 2];
		const inputLocalDesc = outputArray[indexMap.copy.offDesc["otherOffDesc_local-#-desc"] - 2];

		for (let i = 2; i < inputLocalTitles.length; i++) {
			if (inputLocalTitles[i] !== "") {
				localOfficeDescriptions[inputLocalTitles[i]] = inputLocalDesc[i] || "";
			}
		}

		//state custom offices descriptions
		const stateOfficeDescriptions = {};

		const inputStateTitles = outputArray[indexMap.copy.offDesc["otherOffDesc_state-#-title"] - 2];
		const inputStateDesc = outputArray[indexMap.copy.offDesc["otherOffDesc_state-#-desc"] - 2];

		for (let i = 2; i < inputStateTitles.length; i++) {
			if (inputStateTitles[i] !== "") {
				stateOfficeDescriptions[inputStateTitles[i]] = inputStateDesc[i] || "";
			}
		}

		//national custom offices descriptions
		const nationalOfficeDescriptions = {};

		const inputNationalTitles = outputArray[indexMap.copy.offDesc["otherOffDesc_national-#-title"] - 2];
		const inputNationalDesc = outputArray[indexMap.copy.offDesc["otherOffDesc_national-#-desc"] - 2];

		for (let i = 2; i < inputNationalTitles.length; i++) {
			if (inputNationalTitles[i] !== "") {
				nationalOfficeDescriptions[inputNationalTitles[i]] = inputNationalDesc[i] || "";
			}
		}

		//map subject areas to arrays
		const policyDomain1 = {
			title: outputArray[indexMap.copy.policy.title1 - 2][2] || "",
			overview: outputArray[indexMap.copy.policy["title-1-overview"] - 2][2] || "",
			subjectAreas: {},
		};
		//for each subject area title, skipping the first 2 cells (lables)
		for (let i = 2; i < outputArray[indexMap.copy.policy["title-1-subjectAreaTitles"] - 2].length; i++) {
			if (outputArray[indexMap.copy.policy["title-1-subjectAreaTitles"] - 2][i] !== "") {
				const thisSources = [];
				//bullet points 1 through 5
				for (let j = 1; j < 6; j++) {
					if (outputArray[indexMap.copy.policy["title-1-bullet" + j] - 2][i] !== "") {
						thisSources.push({
							description: outputArray[indexMap.copy.policy["title-1-bullet" + j] - 2][i] || "",
							link: outputArray[indexMap.copy.policy["title-1-link" + j] - 2][i] || "",
						});
					}
				}

				policyDomain1.subjectAreas[outputArray[indexMap.copy.policy["title-1-subjectAreaTitles"] - 2][i].replace(/\s/g, "")] = {
					title: outputArray[indexMap.copy.policy["title-1-subjectAreaTitles"] - 2][i] || "",
					overview: outputArray[indexMap.copy.policy["title-1-subjectAreaOverviews"] - 2][i] || "",
					sources: thisSources,
				};
			}
		};

		const policyDomain2 = {
			title: outputArray[indexMap.copy.policy.title2 - 2][2] || "",
			overview: outputArray[indexMap.copy.policy["title-2-overview"] - 2][2] || "",
			subjectAreas: {},
		};
		//for each subject area title, skipping the first 2 cells (lables)
		for (let i = 2; i < outputArray[indexMap.copy.policy["title-2-subjectAreaTitles"] - 2].length; i++) {
			if (outputArray[indexMap.copy.policy["title-2-subjectAreaTitles"] - 2][i] !== "") {
				const thisSources = [];
				//bullet points 1 through 5
				for (let j = 1; j < 6; j++) {
					if (outputArray[indexMap.copy.policy["title-2-bullet" + j] - 2][i] !== "") {
						thisSources.push({
							description: outputArray[indexMap.copy.policy["title-2-bullet" + j] - 2][i] || "",
							link: outputArray[indexMap.copy.policy["title-2-link" + j] - 2][i] || "",
						});
					}
				}

				policyDomain2.subjectAreas[outputArray[indexMap.copy.policy["title-2-subjectAreaTitles"] - 2][i].replace(/\s/g, "")] = {
					title: outputArray[indexMap.copy.policy["title-2-subjectAreaTitles"] - 2][i] || "",
					overview: outputArray[indexMap.copy.policy["title-2-subjectAreaOverviews"] - 2][i] || "",
					sources: thisSources,
				};
			}
		};

		const policyDomain3 = {
			title: outputArray[indexMap.copy.policy.title3 - 2][2] || "",
			overview: outputArray[indexMap.copy.policy["title-3-overview"] - 2][2] || "",
			subjectAreas: {},
		};
		//for each subject area title, skipping the first 2 cells (lables)
		for (let i = 2; i < outputArray[indexMap.copy.policy["title-3-subjectAreaTitles"] - 2].length; i++) {
			if (outputArray[indexMap.copy.policy["title-3-subjectAreaTitles"] - 2][i] !== "") {
				const thisSources = [];
				//bullet points 1 through 5
				for (let j = 1; j < 6; j++) {
					if (outputArray[indexMap.copy.policy["title-3-bullet" + j] - 2][i] !== "") {
						thisSources.push({
							description: outputArray[indexMap.copy.policy["title-3-bullet" + j] - 2][i] || "",
							link: outputArray[indexMap.copy.policy["title-3-link" + j] - 2][i] || "",
						});
					}
				}

				policyDomain3.subjectAreas[outputArray[indexMap.copy.policy["title-3-subjectAreaTitles"] - 2][i].replace(/\s/g, "")] = {
					title: outputArray[indexMap.copy.policy["title-3-subjectAreaTitles"] - 2][i] || "",
					overview: outputArray[indexMap.copy.policy["title-3-subjectAreaOverviews"] - 2][i] || "",
					sources: thisSources,
				};
			}
		};

		const policyDomain4 = {
			title: outputArray[indexMap.copy.policy.title4 - 2][2] || "",
			overview: outputArray[indexMap.copy.policy["title-4-overview"] - 2][2] || "",
			subjectAreas: {},
		};
		//for each subject area title, skipping the first 2 cells (lables)
		for (let i = 2; i < outputArray[indexMap.copy.policy["title-4-subjectAreaTitles"] - 2].length; i++) {
			if (outputArray[indexMap.copy.policy["title-4-subjectAreaTitles"] - 2][i] !== "") {
				const thisSources = [];
				//bullet points 1 through 5
				for (let j = 1; j < 6; j++) {
					if (outputArray[indexMap.copy.policy["title-4-bullet" + j] - 2][i] !== "") {
						thisSources.push({
							description: outputArray[indexMap.copy.policy["title-4-bullet" + j] - 2][i] || "",
							link: outputArray[indexMap.copy.policy["title-4-link" + j] - 2][i] || "",
						});
					}
				}

				policyDomain4.subjectAreas[outputArray[indexMap.copy.policy["title-4-subjectAreaTitles"] - 2][i].replace(/\s/g, "")] = {
					title: outputArray[indexMap.copy.policy["title-4-subjectAreaTitles"] - 2][i] || "",
					overview: outputArray[indexMap.copy.policy["title-4-subjectAreaOverviews"] - 2][i] || "",
					sources: thisSources,
				};
			}
		};

		const policyDomain5 = {
			title: outputArray[indexMap.copy.policy.title5 - 2][2] || "",
			overview: outputArray[indexMap.copy.policy["title-5-overview"] - 2][2] || "",
			subjectAreas: {},
		};
		//for each subject area title, skipping the first 2 cells (lables)
		for (let i = 2; i < outputArray[indexMap.copy.policy["title-5-subjectAreaTitles"] - 2].length; i++) {
			if (outputArray[indexMap.copy.policy["title-5-subjectAreaTitles"] - 2][i] !== "") {
				const thisSources = [];
				//bullet points 1 through 5
				for (let j = 1; j < 6; j++) {
					if (outputArray[indexMap.copy.policy["title-5-bullet" + j] - 2][i] !== "") {
						thisSources.push({
							description: outputArray[indexMap.copy.policy["title-5-bullet" + j] - 2][i] || "",
							link: outputArray[indexMap.copy.policy["title-5-link" + j] - 2][i] || "",
						});
					}
				}

				policyDomain5.subjectAreas[outputArray[indexMap.copy.policy["title-5-subjectAreaTitles"] - 2][i].replace(/\s/g, "")] = {
					title: outputArray[indexMap.copy.policy["title-5-subjectAreaTitles"] - 2][i] || "",
					overview: outputArray[indexMap.copy.policy["title-5-subjectAreaOverviews"] - 2][i] || "",
					sources: thisSources,
				};
			}
		};

		const output = {
			en: {
				homepage: {
					header: outputArray[indexMap.copy.homepage.header - 2][2] || "",
					"about-headline": outputArray[indexMap.copy.homepage["about-headline"] - 2][2] || "",
					"about-overview": outputArray[indexMap.copy.homepage["about-overview"] - 2][2] || "",
					"donate-headline": outputArray[indexMap.copy.homepage["donate-headline"] - 2][2] || "",
					"donate-overview": outputArray[indexMap.copy.homepage["donate-overview"] - 2][2] || "",
					"donate-alt-cta": outputArray[indexMap.copy.homepage["donate-alt-cta"] - 2][2] || "",
				},
				about: {
					header: outputArray[indexMap.copy.about.header - 2][2] || "",
					"story-header": outputArray[indexMap.copy.about["story-header"] - 2][2] || "",
					"story-overview": outputArray[indexMap.copy.about["story-overview"] - 2][2] || "",
					"mission-header": outputArray[indexMap.copy.about["mission-header"] - 2][2] || "",
					"mission-overview": outputArray[indexMap.copy.about["mission-overview"] - 2][2] || "",
					"team-header": outputArray[indexMap.copy.about["team-header"] - 2][2] || "",
					"team-members": teamMembers,
					press: pressItems,
					"volunteer-header": outputArray[indexMap.copy.about["volunteer-header"] - 2][2] || "",
					"volunteer-overview": outputArray[indexMap.copy.about["volunteer-overview"] - 2][2] || "",
					"donate-headline": outputArray[indexMap.copy.about["donate-headline"] - 2][2] || "",
					"donate-overview": outputArray[indexMap.copy.about["donate-overview"] - 2][2] || "",
				},
				topics: {
					overview: outputArray[indexMap.copy.topics.overview - 2][2] || "",
				},
				contact: {
					header: outputArray[indexMap.copy.contact.header - 2][2] || "",
					links: contactItems,
				},
				candidates: {
					cta: outputArray[indexMap.copy.candidates.cta - 2][2] || "",
					empty: outputArray[indexMap.copy.candidates.empty - 2][2] || "",
				},
				offDesc: {
					offDesc_mayorlocal: outputArray[indexMap.copy.offDesc.offDesc_mayorlocal - 2][2] || "",
					offDesc_citycouncilmemberlocal: outputArray[indexMap.copy.offDesc.offDesc_citycouncilmemberlocal - 2][2] || "",
					offDesc_aldermanlocal: outputArray[indexMap.copy.offDesc.offDesc_aldermanlocal - 2][2] || "",
					offDesc_citycommissionerlocal: outputArray[indexMap.copy.offDesc.offDesc_citycommissionerlocal - 2][2] || "",
					offDesc_countycommissionerlocal: outputArray[indexMap.copy.offDesc.offDesc_countycommissionerlocal - 2][2] || "",
					offDesc_cityattorneylocal: outputArray[indexMap.copy.offDesc.offDesc_cityattorneylocal - 2][2] || "",
					offDesc_citymanagerlocal: outputArray[indexMap.copy.offDesc.offDesc_citymanagerlocal - 2][2] || "",
					offDesc_citytreasurerlocal: outputArray[indexMap.copy.offDesc.offDesc_citytreasurerlocal - 2][2] || "",
					offDesc_comptrollerlocal: outputArray[indexMap.copy.offDesc.offDesc_comptrollerlocal - 2][2] || "",
					offDesc_schoolboardmemberlocal: outputArray[indexMap.copy.offDesc.offDesc_schoolboardmemberlocal - 2][2] || "",
					offDesc_schoolboardpresidentlocal: outputArray[indexMap.copy.offDesc.offDesc_schoolboardpresidentlocal - 2][2] || "",
					offDesc_precinctcommitteepersonlocal: outputArray[indexMap.copy.offDesc.offDesc_precinctcommitteepersonlocal - 2][2] || "",
					offDesc_countyprosecutingattorneylocal: outputArray[indexMap.copy.offDesc.offDesc_countyprosecutingattorneylocal - 2][2] || "",
					offDesc_countytreasurerlocal: outputArray[indexMap.copy.offDesc.offDesc_countytreasurerlocal - 2][2] || "",
					offDesc_clerkofcourtstate: outputArray[indexMap.copy.offDesc.offDesc_clerkofcourtstate - 2][2] || "",
					offDesc_staterepresentativestate: outputArray[indexMap.copy.offDesc.offDesc_staterepresentativestate - 2][2] || "",
					offDesc_stateassemblypersonstate: outputArray[indexMap.copy.offDesc.offDesc_stateassemblypersonstate - 2][2] || "",
					offDesc_statesenatorstate: outputArray[indexMap.copy.offDesc.offDesc_statesenatorstate - 2][2] || "",
					offDesc_governorstate: outputArray[indexMap.copy.offDesc.offDesc_governorstate - 2][2] || "",
					offDesc_stateattorneystate: outputArray[indexMap.copy.offDesc.offDesc_stateattorneystate - 2][2] || "",
					offDesc_lieutenantgovernorstate: outputArray[indexMap.copy.offDesc.offDesc_lieutenantgovernorstate - 2][2] || "",
					offDesc_secretaryofstatestate: outputArray[indexMap.copy.offDesc.offDesc_secretaryofstatestate - 2][2] || "",
					offDesc_attorneygeneralstate: outputArray[indexMap.copy.offDesc.offDesc_attorneygeneralstate - 2][2] || "",
					offDesc_statesupremecourtjusticestate: outputArray[indexMap.copy.offDesc.offDesc_statesupremecourtjusticestate - 2][2] || "",
					offDesc_treasrerstate: outputArray[indexMap.copy.offDesc.offDesc_treasrerstate - 2][2] || "",
					offDesc_ushouserepresentativenational: outputArray[indexMap.copy.offDesc.offDesc_ushouserepresentativenational - 2][2] || "",
					offDesc_ussenatornational: outputArray[indexMap.copy.offDesc.offDesc_ussenatornational - 2][2] || "",
					offDesc_presidentnational: outputArray[indexMap.copy.offDesc.offDesc_presidentnational - 2][2] || "",
					offDesc_vicepresidentnational: outputArray[indexMap.copy.offDesc.offDesc_vicepresidentnational - 2][2] || "",
					offDesc_other_local: localOfficeDescriptions,
					offDesc_other_state: stateOfficeDescriptions,
					offDesc_other_national: nationalOfficeDescriptions,
				},
			},
		};

		//map subject areas to topics under policy domain names
		output.en.topics[outputArray[indexMap.copy.policy.title1 - 2][2].replace(/\s/g, "").toLowerCase()] = policyDomain1;
		output.en.topics[outputArray[indexMap.copy.policy.title2 - 2][2].replace(/\s/g, "").toLowerCase()] = policyDomain2;
		output.en.topics[outputArray[indexMap.copy.policy.title3 - 2][2].replace(/\s/g, "").toLowerCase()] = policyDomain3;
		output.en.topics[outputArray[indexMap.copy.policy.title4 - 2][2].replace(/\s/g, "").toLowerCase()] = policyDomain4;
		output.en.topics[outputArray[indexMap.copy.policy.title5 - 2][2].replace(/\s/g, "").toLowerCase()] = policyDomain5;

		outputArray = [output];
	}

	console.log(outputArray);
	return outputArray;
};

CSVHandler.dataValidator = (formattedData, type) => {
	const errorOutput = [];

	let thisError = "";

	switch (type) {
		case "V2 Legislations": {
			for (let i = 0; i < formattedData.length; i++) {
				if (Object.keys(formattedData[i]).length > 0) { //has at least one key, and is not an empty or unchecked row
					if (formattedData[i].data.level === "") {
						thisError += " Missing level, or office formatting error.";
					}

					if (formattedData[i].data.title === "") {
						thisError += " Missing title.";
					}

					if (formattedData[i].data.billNumber === "") {
						thisError += " Missing bill number.";
					}

					if (
						formattedData[i].data.zipCodes === [""]) {
						thisError += " Missing zip codes.";
					}

					if (formattedData[i].data.sponsors.formatError) {
						thisError += " Sponsors format error.";
					}

					if (formattedData[i].data.cosponsors.formatError) {
						thisError += " Cosponsors format error.";
					}

					//TODO: finish writing validations
					//TODO: check for sponsor and cosponsor validation

					//Finished validating this row
					if (thisError !== "") {
						thisError = "Validation Error for Row " + (i + 3) + ":" + thisError; //NB: data rows start from 3 due to an example row

						errorOutput.push(thisError);
						thisError = "";
					}
				}
			}
			break;
		}
		case "V2 Candidates": {
			for (let i = 0; i < formattedData.length; i++) {
				if (Object.keys(formattedData[i]).length > 0) { //has at least one key, and is not an empty or unchecked row
					//optional: district, state, county, and circuit
					if (formattedData[i].data.name === "") {
						thisError += " Missing name.";
					}

					if (formattedData[i].data.office === "") {
						thisError += " Missing office.";
					}

					if (formattedData[i].data.level === "") {
						thisError += " Missing level.";
					}

					if (formattedData[i].data.zipCodes === "") {
						thisError += " Missing zipCodes.";
					}

					//TODO: finish writing validations

					//Finished validating this row
					if (thisError !== "") {
						thisError = "Validation Error for Row " + (i + 2) + ":" + thisError;

						errorOutput.push(thisError);
						thisError = "";
					}
				}
			}
			break;
		}
		case "V2 Events": {
			for (let i = 0; i < formattedData.length; i++) {
				if (Object.keys(formattedData[i]).length > 0) { //has at least one key, and is not an empty or unchecked row
					if (formattedData[i].data.title === "") {
						thisError += " Missing title.";
					}

					if (formattedData[i].data.date === "") {
						thisError += " Missing date.";
					}

					// if (formattedData[i].data.startTime === "") {
					// 	thisError += " Missing starting time.";
					// }

					// if (formattedData[i].data.timeZone === "") {
					// 	thisError += " Missing time zone.";
					// }

					// if (formattedData[i].data.link === "") {
					// 	thisError += " Missing link.";
					// }

					// if (formattedData[i].data.eventImage === "") {
					// 	thisError += " Missing image link.";
					// }

					if (formattedData[i].data.isWelcomeCall === "") {
						thisError += " Missing isWelcomeCall.";
					}

					if (formattedData[i].data.isElection === "") {
						thisError += " Missing isElection.";
					}

					if (
						formattedData[i].data.isWelcomeCall === "Y"
						&& formattedData[i].data.isElection === "Y"
					) {
						thisError += " is marked as both welcome call and election at the same time";
					}

					//Finished validating this row
					if (thisError !== "") {
						thisError = "Validation Error for Row " + (i + 2) + ":" + thisError;

						errorOutput.push(thisError);
						thisError = "";
					}
				}
			}
			break;
		}
		case "V2 Copy": {
			//TODO: implement validation
			break;
		}
		default: {
			// eslint-disable-next-line no-alert
			alert("Invalid type on candidateValidator", type);
		}
	}

	return errorOutput;
};

CSVHandler.dataUploader = (formattedData, isStaging, type) => {
	return new Promise((resolve, reject) => {
		let batches = [];

		if (type === "V2 Legislations") {
			batches = formatter.chunkArray(formattedData, 25);
		} else {
			batches = formatter.chunkArray(formattedData, 200);
		}
		const promises = [];

		batches.forEach((dataBatch) => {
			promises.push(Vue.prototype.$fbApi("admin/CSVUpload", { dataBatch, isStaging, type }));
		});

		Promise.all(promises).then((results) => {
			resolve(results);
		}).catch((err) => {
			reject(err);
		});
	});
};

//Remove entries that aren't specified in the formattedData
CSVHandler.dataCleaner = (formattedData, isStaging, type) => {
	return new Promise((resolve, reject) => {
	//build array of IDs
		const idArray = [];
		const removedCandidates = [];

		formattedData.forEach((candidate) => {
			if (
				candidate.data
				&& Object.keys(candidate.data).length > 0
				&& candidate.data.active
			) {
				console.log("dc type: ", type);
				idArray.push(formatter.docKeyGen(candidate.data, null, type));
			}
		});

		console.log("idArray", idArray);

		//read current candidates
		Vue.prototype.$fbApi("admin/CSVRead", { isStaging, type }).then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				console.log("doc.id: ", doc.id);
				if (!idArray.includes(doc.id)) {
					removedCandidates.push(doc.id);
				}
			});

			console.log("pre batches", removedCandidates);
			const batches = formatter.chunkArray(removedCandidates, 400);
			console.log("post batches", batches);
			const promises = [];

			batches.forEach((dataBatch) => {
				promises.push(Vue.prototype.$fbApi("admin/CSVDelete", { dataBatch, isStaging, type }));
			});

			Promise.all(promises).then(() => {
				resolve(removedCandidates);
			}).catch((err) => {
				reject(err);
			});
		}).catch((err) => {
			// eslint-disable-next-line no-alert
			alert("Clean error: " + err);
		});
	});
};

//Delete all current records - used for events
CSVHandler.dataPurge = (isStaging, type) => {
	return new Promise((resolve, reject) => {
		const removedEntries = [];

		//read current candidates
		Vue.prototype.$fbApi("admin/CSVRead", { isStaging, type }).then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				removedEntries.push(doc.id);
			});

			console.log("pre batches", removedEntries);
			const batches = formatter.chunkArray(removedEntries, 400);
			console.log("post batches", batches);
			const promises = [];

			batches.forEach((dataBatch) => {
				promises.push(Vue.prototype.$fbApi("admin/CSVDelete", { dataBatch, isStaging, type }));
			});

			Promise.all(promises).then(() => {
				resolve(removedEntries);
			}).catch((err) => {
				reject(err);
			});
		}).catch((err) => {
			// eslint-disable-next-line no-alert
			alert("Clean error: " + err);
		});
	});
};

CSVHandler.validRowCounter = (formattedData, type) => {
	let count = 0;

	if (type === "V2 Copy") {
		count = formattedData.length;
	} else {
		for (let i = 0; i < formattedData.length; i++) {
			if (
				formattedData[i].data
				&& formattedData[i].data.active
			) { //filters both inactive and unchecked (empty) rows
				count++;
			}
		}
	}

	return count;
};

export default CSVHandler;
