<template>
	<div class="legislation-search-display">
		<h2 class="legislation-search-display__heading headline-l">
			{{ $t("components.topics.legislationSearchDisplay.heading") }}
		</h2>
		<h3
			class="legislation-search-display__subheading headline-s"
			v-if="!zipCode || zipCode === ''"
		>
			{{ $t("components.topics.legislationSearchDisplay.subheading") }}
		</h3>
		<h3
			class="legislation-search-display__subheading headline-s"
			v-if="zipCode && zipCode !== ''"
		>
			{{ $t("components.topics.legislationSearchDisplay.emptySubheading") }}
		</h3>
		<div
			class="legislation-search-display__search-bar"
		>
			<zip-search @zipSearchSubmit="handleZipSearchSubmit"/>
		</div>
		<p class="legislation-search-display__disclaimer">
			{{ $t("components.topics.legislationSearchDisplay.disclaimer") }}
		</p>
	</div>
</template>

<script>
import ZipSearch from "../../Common/ZipSearch/ZipSearch.vue";

export default {
	name: "legislation-search-display",
	components: {
		ZipSearch,
	},
	props: {
		zipCode: {
			type: String,
			default: "",
		},
		copy: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	methods: {
		handleZipSearchSubmit(zipCode) {
			this.$emit("zipSearchSubmit", zipCode);
		},
	},
};
</script>

<style lang="stylus" scoped>
@import "../../../styles/global.styl"

.legislation-search-display
	text-align: center
	color: $lightest-purple

	&__heading
		margin: 2rem

	&__subheading
		margin-bottom: 4rem

	&__search-bar
		margin-top: 2rem
		margin-bottom: 4rem
		margin-left: auto
		margin-right: auto

	&__disclaimer
		margin: 2rem

</style>
