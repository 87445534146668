<template>
	<div class="home-page page">
		<h1>
			{{$t('views.admin.title')}}
		</h1>

		<AdminHomeComponent />
	</div>
</template>

<script>
import AdminHomeComponent from "../components/Admin/AdminHomeComponent/AdminHomeComponent.vue";

export default {
	name: "Admin",
	components: {
		AdminHomeComponent,
	},
};
</script>

<style lang="stylus" scoped>
.home-page
	text-align: center
</style>
