const mockCandidate = {
	active: true,
	valid: true, //legacy
	createdAt: "TODO: String", //Date.now() unix epoch
	createdBy: "Mockingbird",
	circuit: "",
	county: "County",
	district: "5",
	incumbent: "true",
	level: "national",
	office: "US House of Representatives", //NB: non-lowercased
	name: "Jaycee Magnuson",
	party: "REP", //ID e.g. "DEM"
	state: "",
	imageLink: "https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/jmagnuson.jpg", //URL
	link: "String", //URL to campaign website
	zipCodes: [
		"10000", "10001", "10002",
	],
	overview: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus a semper dolor. Fusce venenatis, diam eget eleifend eleifend, sapien mauris iaculis odio, eu aliquet leo sem ut enim. Maecenas ullamcorper vitae orci in ullamcorper. Fusce molestie justo quis augue interdum, in commodo ligula pharetra. Nulla facilisi. Mauris nunc mauris, condimentum vitae turpis quis, gravida ullamcorper metus. Donec eget odio ac mi dictum sagittis at non nulla. Pellentesque non est non turpis aliquam suscipit.", //Full copy text
	articles: [
		{
			title: "Article 1 title that is long enough to require a text wrap, as well as card resizing, which is common", //Article title
			source: "MV P", //String name of the publication
			link: "https://google.com", //URL
		},
		{
			title: "Article 2 title that is long enough to require a text wrap, as well as card resizing, which is common", //Article title
			source: "MV P", //String name of the publication
			link: "https://google.com", //URL
		},
		{
			title: "Article 3 title that is long enough to require a text wrap, as well as card resizing, which is common", //Article title
			source: "MV P", //String name of the publication
			link: "https://google.com", //URL
		},
		{
			title: "Article 4 title that is long enough to require a text wrap, as well as card resizing, which is common", //Article title
			source: "MV P", //String name of the publication
			link: "https://google.com", //URL
		},
		{
			title: "Article 5 title that is long enough to require a text wrap, as well as card resizing, which is common", //Article title
			source: "MV P", //String name of the publication
			link: "https://google.com", //URL
		},
	],
	legislations: [
		{
			billNumber: "M.B.001", //e.g. "H.R.123" using the primary source's formatting for consistency
			title: "Placeholder lesgislation for the purpose of mocking up components", //do not include the bill Number. exact copy from the primary source
			link: "https://google.com", //URL
		},
		{
			billNumber: "M.B.002", //e.g. "H.R.123" using the primary source's formatting for consistency
			title: "Continued efforts to provide a placeholder lesgislation for mocking up components", //do not include the bill Number. exact copy from the primary source
			link: "https://google.com", //URL
		},
		{
			billNumber: "M.B.003", //e.g. "H.R.123" using the primary source's formatting for consistency
			title: "Final placeholder lesgislation for the purpose of mocking up components", //do not include the bill Number. exact copy from the primary source
			link: "https://google.com", //URL
		},
	],
	focusAreas: [ //Expect matching
		"Healthcare", "COVID-19", "Economy", //Can be the exact formatted and styled text of a policy domain or subject area, or
	],
	otherFocusAreas: [ //No matching expected
		"Holding Places", "Mocking Up", "Preservation of Mockingbirds", "Lorem", //Areas of focus that aren't a part of researched policy domain/subject area
	],
	social: {
		facebook: "https://facebook.com", //URL
		twitter: "https://twitter.com", //URL
		youtube: "https://youtube.com", //URL
		linkedin: "https://linkedin.com", //URL
		instagram: "https://instagram.com", //URL
	},
};

export default {
	mockCandidate,
};
