/* eslint-disable import/first */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./plugins/i18n";

import firebase from "./plugins/firebase";

// This attaches $fbApi to the Vue prototype, exposing it in all components and in
// the Vuex store when initializing (by importing Vue in the store module files).
Vue.use(firebase);

// Because the actual `new Vuex.Store...` happens in the file imported here, it must
// come after `Vue.use(firebase)`. There may be a slightly cleaner way to do this, but
// this seems fairly straightforward. The only weirdness is relying on importing Vue after
// the prototype has been modified, which may be surmountable. I'm not really sure.
import store from "./store";

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount("#app");
