function InitializeFirebaseCandidates(config) {
	const { db } = config;

	return {
		name: "candidates",

		async fetchByZipCode(input) {
			return new Promise((resolve, reject) => {
				db.collection("V2Candidates").where(
					"zipCodes",
					"array-contains",
					input.zipCode,
				)
					.get()
					.then((querySnapshot) => {
						const output = {
							national: {},
							state: {},
							local: {},
						};
						querySnapshot.forEach((doc) => {
							switch (doc.data().level) {
								case "national": {
									const candidateData = doc.data();
									candidateData.candidateKey = doc.id;
									if (!output.national[candidateData.office]) {
										output.national[candidateData.office] = [];
									}
									output.national[candidateData.office].push(candidateData);
									break;
								}
								case "state": {
									const candidateData = doc.data();
									candidateData.candidateKey = doc.id;
									if (!output.state[candidateData.office]) {
										output.state[candidateData.office] = [];
									}
									output.state[candidateData.office].push(candidateData);
									break;
								}
								case "local": {
									const candidateData = doc.data();
									candidateData.candidateKey = doc.id;
									if (!output.local[candidateData.office]) {
										output.local[candidateData.office] = [];
									}
									output.local[candidateData.office].push(candidateData);
									break;
								}
								default: {
									console.log(`Candidate ${doc.data().name} has an invalid level: ${doc.data().levle}`);
								}
							}
						});
						resolve(output);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
		async fetchCandidateData(input) {
			return new Promise((resolve, reject) => {
				db.collection("V2Candidates").doc(input.candidateKey).get()
					.then((querySnapshot) => {
						resolve(querySnapshot.data());
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
	};
};

export default InitializeFirebaseCandidates;
