<template>
	<div class="adminPanel-container">
		<div v-show="!computedAuth.authenticated" id="firebase-ui-target"></div>
		<div v-show="computedAuth.authenticated" class="adminPanel-selectionBox">
			<span>
				Select spreadsheet to update from:
			</span>
			<select
				class="adminPanel-type__select"
				v-model="type"
				@change="selectionChange"
			>
				<option
					class="adminPanel-type__option"
					value="Legacy Candidates"
				>
					Legacy Candidates
				</option>
				<option
					class="adminPanel-type__option"
					value="V2 Legislations"
				>
					V2 Legislations
				</option>
				<option
					class="adminPanel-type__option"
					value="V2 Candidates"
				>
					V2 Candidates
				</option>
				<option
					class="adminPanel-type__option"
					value="V2 Events"
				>
					V2 Events
				</option>
				<option
					class="adminPanel-type__option"
					value="V2 Copy"
				>
					V2 Copy
				</option>
			</select>
		</div>
		<hr class="adminPanel-selectionBox__divider" />
		<div class="adminPanel-authInfo">
			<p>Logged in as:</p>
			<p>Name: {{computedAuth.displayName}}</p>
			<p>Email: {{computedAuth.email}}</p>
			<p>UID: {{computedAuth.uid}}</p>
			<button v-on:click="deauth">Sign Out</button>
		</div>
		<div v-show="type==='Legacy Candidates'">
			<a
				:href="'https://docs.google.com/spreadsheets/d/' + spreadsheets['Legacy Candidates']"
				target="__blank"
			>
				Data will be fetched from this spreadsheet
			</a>
			<p v-show="!computedAuth.authenticated">{{$t('components.admin.adminHomeComponent.copy')}}</p>
			<div v-show="computedAuth.authenticated">
				<p></p>
				<button v-on:click="FetchCSV('Legacy Candidates')">Fetch Legacy Candidate Data from the Spreadsheet</button>
				<p></p>
				<button v-on:click="UploadCSV('Legacy Candidates', false, false)">UPLOAD: Append Legacy Candidate Data</button>
				<button v-on:click="UploadCSV('Legacy Candidates', true, false)">UPLOAD: Update and Remove Candidate Data from CSV</button>
				<p></p>
				<button v-on:click="UploadCSV('Legacy Candidates', false, true)">UPLOAD-STAGING: Append Legacy Candidate Data</button>
				<button v-on:click="UploadCSV('Legacy Candidates', true, true)">UPLOAD-STAGING: Update and Remove Candidate Data from CSV</button>
				<p></p>
				<button v-on:click="IdentifyIndex('Legacy Candidates')">DEV - Identify Indexes</button>
			</div>
			<div v-show="computedAuth.authenticated">
				<p>Status: {{status}}</p>
				<p>{{validationErrors}}</p>
			</div>
		</div>
		<div v-show="type==='V2 Legislations'">
			<a
				:href="'https://docs.google.com/spreadsheets/d/' + spreadsheets['V2 Legislations']"
				target="__blank"
			>
				Data will be fetched from this spreadsheet
			</a>
			<p v-show="!computedAuth.authenticated">{{$t('components.admin.adminHomeComponent.copy')}}</p>
			<div v-show="computedAuth.authenticated">
				<p></p>
				<button v-on:click="FetchCSV('V2 Legislations')">Fetch Legislation Data from the Spreadsheet</button>
				<p></p>
				<button v-on:click="UploadCSV('V2 Legislations', false, false)">UPLOAD: Append Legislation Data</button>
				<button v-on:click="UploadCSV('V2 Legislations', true, false)">UPLOAD: Update and Remove Legislation Data from CSV</button>
				<p></p>
				<button v-on:click="UploadCSV('V2 Legislations', false, true)">UPLOAD-STAGING: Append Legislation Data</button>
				<button v-on:click="UploadCSV('V2 Legislations', true, true)">UPLOAD-STAGING: Update and Remove Legislation Data from CSV</button>
				<p></p>
				<button v-on:click="IdentifyIndex('V2 Legislations')">DEV - Identify Indexes</button>
			</div>
			<div v-show="computedAuth.authenticated">
				<p>Status: {{status}}</p>
				<p>{{validationErrors}}</p>
			</div>
		</div>
		<div v-show="type==='V2 Candidates'">
			<a
				:href="'https://docs.google.com/spreadsheets/d/' + spreadsheets['V2 Candidates']"
				target="__blank"
			>
				Data will be fetched from this spreadsheet
			</a>
			<p v-show="!computedAuth.authenticated">{{$t('components.admin.adminHomeComponent.copy')}}</p>
			<div v-show="computedAuth.authenticated">
				<p></p>
				<button v-on:click="FetchCSV('V2 Candidates')">Fetch Candidate Data from the Spreadsheet</button>
				<p></p>
				<button v-on:click="UploadCSV('V2 Candidates', false, false)">UPLOAD: Append Candidate Data</button>
				<button v-on:click="UploadCSV('V2 Candidates', true, false)">UPLOAD: Update and Remove Candidate Data from CSV</button>
				<p></p>
				<button v-on:click="UploadCSV('V2 Candidates', false, true)">UPLOAD-STAGING: Append Candidate Data</button>
				<button v-on:click="UploadCSV('V2 Candidates', true, true)">UPLOAD-STAGING: Update and Remove Candidate Data from CSV</button>
				<p></p>
				<button v-on:click="IdentifyIndex('V2 Candidates')">DEV - Identify Indexes</button>
			</div>
			<div v-show="computedAuth.authenticated">
				<p>Status: {{status}}</p>
				<p>{{validationErrors}}</p>
			</div>
		</div>
		<div v-show="type==='V2 Events'">
			<a
				:href="'https://docs.google.com/spreadsheets/d/' + spreadsheets['V2 Events']"
				target="__blank"
			>
				Data will be fetched from this spreadsheet
			</a>
			<p v-show="!computedAuth.authenticated">{{$t('components.admin.adminHomeComponent.copy')}}</p>
			<div v-show="computedAuth.authenticated">
				<p></p>
				<button v-on:click="FetchCSV('V2 Events')">Fetch Event Data from the Spreadsheet</button>
				<p></p>
				<button v-on:click="UploadCSV('V2 Events', true, false)">UPLOAD: Update and Remove Event Data from CSV</button>
				<p></p>
				<button v-on:click="UploadCSV('V2 Events', true, true)">UPLOAD-STAGING: Update and Remove Event Data from CSV</button>
				<p></p>
				<button v-on:click="IdentifyIndex('V2 Events')">DEV - Identify Indexes</button>
			</div>
			<div v-show="computedAuth.authenticated">
				<p>Status: {{status}}</p>
				<p>{{validationErrors}}</p>
			</div>
		</div>
		<div v-show="type==='V2 Copy'">
			<a
				:href="'https://docs.google.com/spreadsheets/d/' + spreadsheets['V2 Copy']"
				target="__blank"
			>
				Data will be fetched from this spreadsheet
			</a>
			<p v-show="!computedAuth.authenticated">{{$t('components.admin.adminHomeComponent.copy')}}</p>
			<div v-show="computedAuth.authenticated">
				<p></p>
				<button v-on:click="FetchCSV('V2 Copy')">Fetch Copy Data from the Spreadsheet</button>
				<p></p>
				<button v-on:click="UploadCSV('V2 Copy', false, false)">UPLOAD: Update Copy Data</button>
				<p></p>
				<button v-on:click="UploadCSV('V2 Copy', false, true)">UPLOAD-STAGING: Update Copy Data</button>
\				<p></p>
				<button v-on:click="IdentifyIndex('V2 Copy')">DEV - Identify Indexes</button>
			</div>
			<div v-show="computedAuth.authenticated">
				<p>Status: {{status}}</p>
				<p>{{validationErrors}}</p>
			</div>
		</div>
	</div>
</template>

<script>
import "firebaseui/dist/firebaseui.css";
import gapi from "../../../plugins/sheetApi";
import LegacyCSVHandler from "../../../plugins/LegacyCSVHandler";
import CSVHandler from "../../../plugins/CSVHandler";

export default {
	name: "admin-home-component",
	props: {
		showRedBorder: {
			type: Boolean,
			default: false,
		},
	},
	async mounted() {
		const fbConfig = await this.$fbApi("main/getConfig");
		fbConfig.ui.start("#firebase-ui-target", fbConfig.uiOptions);
		fbConfig.auth.onAuthStateChanged((user) => {
			this.$store.dispatch("authChange", user);
		});
	},
	data() {
		return {
			type: "",
			formattedData: [],
			validationErrors: [],
			status: "",
			readyToUpload: false,
			validRows: null,
			spreadsheets: {
				"Legacy Candidates": "1-FyrwX8-Bzl0Je9hd-Yb2M06tAMygbZVOIasds7yFH4",
				"V2 Legislations": "14giDMOVrVnXGhFYPUwZzt8Z8n2FMcF3pVZpkgOoEf5A",
				"V2 Candidates": "1mh3i0xsotrHJCJHWHhAQn6VYLzb9iY4a0ZwmbQhTsX4",
				"V2 Events": "1-oaEceqlkLGCDdiD_I_J7hr94Ix0MbMAlxQ24K8a5Cw",
				"V2 Copy": "1BnxZZlIiF1tPEIdIPRoCZQ7Iiqce4IUMkmIB_fbvh50",
			},
			ranges: {
				"Legacy Candidates": "Headers for Bulk Import!A2:AT",
				"V2 Legislations": "Import!A3:Q",
				"V2 Candidates": "Import!A2:AW",
				"V2 Events": "Import!A2:K",
				"V2 Copy": "Import!A2:133",
			},
		};
	},
	computed: {
		classes() {
			return this.showRedBorder ? "border-red" : "";
		},
		computedAuth() {
			if (this.$store.state.auth) {
				return {
					authenticated: true,
					displayName: this.$store.state.auth.displayName,
					email: this.$store.state.auth.email,
					uid: this.$store.state.auth.uid,
				};
			} else {
				return {
					authenticated: false,
					displayName: null,
					email: null,
					uid: null,
				};
			}
		},
	},
	methods: {
		async deauth() {
			const fbConfig = await this.$fbApi("main/getConfig");
			fbConfig.auth.signOut().then(() => {
				//Sign out successful
			}).catch((err) => {
				//TODO: error reporting
				console.log(err);
			});
		},
		selectionChange() {
			this.formattedData = [];
			this.validationErrors = [];
			this.status = "";
			this.readyToUpload = false;
			this.validRows = null;
		},
		FetchCSV(type) {
			this.status = "Fetching data from the spreadsheet...";
			gapi.load("client", () => {
				gapi.client.init({
					apiKey: "AIzaSyBFideq0IHfwncbwl1yDcaHm_MSiXKKzG0",
					// clientId: "712101825739-04j9ee1g5npo3eeu94nm6ac83pjvi20v.apps.googleusercontent.com",
					// scope: "https://www.googleapis.com/auth/spreadsheets.readonly",
					discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
				}).then(() => {
					gapi.client.sheets.spreadsheets.values.get({
						spreadsheetId: this.spreadsheets[type],
						range: this.ranges[type],
					}).then((response) => {
						var range = response.result;
						if (range.values.length > 0) {
							//TODO: specify behaviors for sheets
							this.status = `Completed fetching ${range.values.length} rows from the spreadsheet. Validating data...`;
							if (type === "Legacy Candidates") {
								this.formattedData = LegacyCSVHandler.legacyCandidateFormatter(range.values);
								this.validationErrors = LegacyCSVHandler.legacyCandidateValidator(this.formattedData);
							} else {
								this.formattedData = CSVHandler.dataFormatter(range.values, type);
								this.validationErrors = CSVHandler.dataValidator(this.formattedData, type);
							}

							if (this.validationErrors.length === 0) {
								this.readyToUpload = true;
								if (type === "Legacy Candidates") {
									this.validRows = LegacyCSVHandler.legacyValidRowCounter(this.formattedData);
									this.status = `Completed fetching ${range.values.length} rows from the spreadsheet. Data validation complete without errors. ${this.validRows} active and fact-checked rows found. Ready to upload`;
								} else {
									this.validRows = CSVHandler.validRowCounter(this.formattedData, type);
									this.status = `Completed fetching ${range.values.length} rows from the spreadsheet. Data validation complete without errors. ${this.validRows} active and fact-checked rows found. Ready to upload`;
								}
							}
						} else {
							this.status = "No data found on the target spreadsheet";
						}
					}, (response) => {
						this.status = `Error fetching data from the spreadsheet: ${response.result.error.message}`;
					});
				});
			});
		},
		IdentifyIndex(type) {
			console.log("Fetching data from the spreadsheet...");
			gapi.load("client", () => {
				gapi.client.init({
					apiKey: "AIzaSyBFideq0IHfwncbwl1yDcaHm_MSiXKKzG0",
					// clientId: "712101825739-04j9ee1g5npo3eeu94nm6ac83pjvi20v.apps.googleusercontent.com",
					// scope: "https://www.googleapis.com/auth/spreadsheets.readonly",
					discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
				}).then(() => {
					gapi.client.sheets.spreadsheets.values.get({
						spreadsheetId: this.spreadsheets[type],
						range: (type === "Legacy Candidates") ? "Headers for Bulk Import!A1:1" : "Import!A1:1",
					}).then((response) => {
						var range = response.result;
						if (range.values.length > 0) {
							// for (let i = 0; i < range.values.length; i++) {
							// 	var row = range.values[i];
							// 	// Print columns A and E, which correspond to indices 0 and 4.
							// 	console.log(row);
							// }
							console.log(`Got ${range.values.length} rows`);

							for (let i = 0; i < range.values[0].length; i++) {
								console.log(`Index ${i} maps to ${range.values[0][i]}`);
							}
						} else {
							console.log("No data found on the target range");
						}
					}, (response) => {
						this.status = `Error fetching data from the spreadsheet: ${response.result.error.message}`;
					});
				});
			});
		},
		UploadCSV(type, isCleanWrite, isStaging) {
			//TODO: specify behaviors for sheets
			if (type === "Legacy Candidates") {
				if (this.readyToUpload) {
					this.status = "Uploading the data...";
					LegacyCSVHandler.legacyCandidateUploader(this.formattedData, isStaging).then(() => {
						this.status = "Successfully uploaded the data.";

						if (isCleanWrite) {
							LegacyCSVHandler.legacyCandidateCleaner(this.formattedData, isStaging).then((removedCandidates) => {
								this.validationErrors = removedCandidates;
								this.status += ` Cleaned the below ${removedCandidates.length} candidates`;
							});
						}
					}).catch((err) => {
						console.log(err);
						this.status = "Failed to uploade the data: " + err;
					});
				} else {
					this.status = "Please fix all errors before trying to upload the data";
				}
			} else if (type === "V2 Events") {
				//TODO: always delete all records, and add current data in sequence
				if (this.readyToUpload) {
					CSVHandler.dataPurge(isStaging, type).then((removedEntries) => {
						this.validationErrors = removedEntries;
						this.status += "Cleaned existing events. Uploading new event data";

						CSVHandler.dataUploader(this.formattedData, isStaging, type).then(() => {
							this.status = "Successfully updated events data";
						});
					}).catch((err) => {
						console.log(err);
						this.status = "Failed to update events data: " + err;
					});
				} else {
					this.status = "Please fix all errors before trying to upload the data";
				}
			} else {
				if (this.readyToUpload) {
					this.status = "Uploading the data...";
					CSVHandler.dataUploader(this.formattedData, isStaging, type).then(() => {
						this.status = "Successfully uploaded the data.";

						if (isCleanWrite) {
							this.status += " Cleaning old data....";
							CSVHandler.dataCleaner(this.formattedData, isStaging, type).then((removedCandidates) => {
								this.validationErrors = removedCandidates;
								this.status += `Successfully uploaded the data. Cleaned the below ${removedCandidates.length} candidates`;
							});
						}
					}).catch((err) => {
						console.log(err);
						this.status = "Failed to uploade the data: " + err;
					});
				} else {
					this.status = "Please fix all errors before trying to upload the data";
				}
			}
		},
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';

.adminPanel-authInfo
	position: absolute
	right: 5rem
	top: 5rem
	padding: 2rem
	border: $pxToRem(4) solid $black
	border-radius: $pxToRem(4)

.adminPanel-selectionBox__divider
	width: 50vw
</style>
