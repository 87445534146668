function InitializeFirebaseCopy(config) {
	const { db } = config;

	return {
		name: "copy",

		fetchCopy() {
			return new Promise((resolve, reject) => {
				db.collection("V2Copy").get().then((querySnapshot) => {
					let output = {};
					//get applicable locale
					const thisLocale = process.env.VUE_APP_I18N_LOCALE || "en";
					querySnapshot.forEach((doc) => {
						if (thisLocale === doc.id) {
							output = doc.data();
						}
					});
					resolve(output);
				}).catch((err) => {
					reject(err);
				});
			});
		},
	};
};

export default InitializeFirebaseCopy;
