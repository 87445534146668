<template>
	<div class="candidates-view">
		<candidates-ActiVote-display
			:candidates="candidates"
			:zipCode="zipCode"
			:copy="copy"
			:candidateLevel="candidateLevel"
			@zipSearchSubmit="handleZipSearchSubmit"
			@candidateLevelSet="handleCandidateLevelSet"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CandidatesActiVoteDisplay from "../components/Candidates/CandidatesActiVoteDisplay/CandidatesActiVoteDisplay.vue";

export default {
	name: "candidates-view",
	components: { CandidatesActiVoteDisplay },
	computed: {
		...mapState("candidates", [
			"candidates",
			"candidateLevel",
		]),
		zipCode() {
			return this.$store.state.zipCode;
		},
		copy() {
			return this.$store.state.copy;
		},
	},
	methods: {
		...mapActions("candidates", {
			fetchCandidatesByZip: "fetchByZipCode",
			setCandidateLevel: "setCandidateLevel",
		}),
		handleZipSearchSubmit(zipCode) {
			this.$fbConfig.analytics.logEvent("zipSearch");
			this.fetchCandidatesByZip(zipCode);
		},
		handleCandidateLevelSet(level) {
			this.setCandidateLevel(level);
		},
	},
	mounted() {
		this.fetchCandidatesByZip(this.zipCode);
		this.$store.dispatch("fetchCopy");
	},
};
</script>

<style lang="stylus" scoped>

</style>
