import Vue from "vue";

export default {
	namespaced: true,
	state: {
		candidates: {
			national: null, // TODO: set default instead of mock data
			state: null,
			local: null,
		},
		candidateLevel: "national",
		loadingAll: false,
		loading: false,
		zipCode: "",
		selectedCandidate: null,
		candidateUnavailable: false,
	},

	mutations: {
		setLoadingAll(state, flag) {
			state.loadingAll = !!flag;
		},

		setLoading(state, flag) {
			state.loading = !!flag;
		},

		setCandidateUnavailable(state, flag) {
			state.candidateUnavailable = !!flag;
		},

		setCandidates(state, candidates) {
			state.candidates.national = candidates?.national || null;
			state.candidates.state = candidates?.state || null;
			state.candidates.local = candidates?.local || null;
		},

		setSelectedCandidate(state, candidateData) {
			state.selectedCandidate = candidateData;
		},

		setLevel(state, selectedLevel) {
			state.candidateLevel = selectedLevel;
		},
	},

	actions: {
		async fetchByZipCode({ commit, state/*, getters, dispatch*/ }, zipCode) {
			if (!state.loadingAll) {
				commit("setZip", zipCode, { root: true });
				if (zipCode && zipCode !== "") { //sometimes fetch is called with empty zip to reset
					commit("setLoadingAll", true);
					const candidates = await Vue.prototype.$fbApi("candidates/fetchByZipCode", { zipCode });
					commit("setCandidates", candidates);
					commit("setLoadingAll", false);
				}
			} else {
				// console.log("load already in progress"); //TODO: toast?
			}
		},

		async setCandidateLevel({ commit/*, state, getters, dispatch*/ }, selectedLevel) {
			commit("setLevel", selectedLevel);
		},

		async selectCandidate({ commit, state/*, getters, dispatch*/ }, candidateKey) {
			//First see if the candidate is available under candidates, which would be the case unless loaded from address rather than navigated from the candidates page.
			commit("setCandidateUnavailable", false);

			//search
			let foundCandidate = null;

			Object.keys(state.candidates).forEach((levelKey) => {
				const thisLevel = state.candidates[levelKey];

				if (thisLevel) {
					Object.keys(thisLevel).forEach((key) => {
						if (key === candidateKey) {
							foundCandidate = thisLevel[key];
						}
					});
				}
			});

			if (foundCandidate) {
				//search result found.
				commit("setSelectedCandidate", foundCandidate);
			} else {
				if (!state.loading) {
					commit("setLoading", true);
					const candidateData = await Vue.prototype.$fbApi("candidates/fetchCandidateData", { candidateKey });
					if (candidateData === undefined) {
						commit("setCandidateUnavailable", true);
						commit("setSelectedCandidate", candidateData);
					} else {
						commit("setSelectedCandidate", candidateData);
					}
					commit("setLoading", false);
				} else {
					// console.log("load already in progress"); //TODO: toast?
				}
			}
		},
	},
};
