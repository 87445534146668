<template>
	<div id="topics-content-top" class="topics-content">
		<header-bar :inverted="false" style="visibility: hidden" /> <!-- TODO: replace this hacky spacer -->
		<header-bar :inverted="false" :fixTop="true" />
		<div class="topics-content__section--purple">
			<div class="topics-content__content-boundary">
				<div class="topics-content__headline">
					<h1 class="topics-content__header headline-xxl">
						{{ $t("components.topics.topicsContent.header") }}
					</h1>
					<p class="topics-content__overview body-l">
						<pre class="body-l">{{ copy.topics ? copy.topics.overview : $t("components.topics.topicsContent.overview") }}</pre>
					</p>
				</div>
				<div class="topics-content__domains">
					<div
						class="topics-content__topic headline-l"
						v-for="topic in topics"
						:key="topic"
						v-bind="topic"
						@click="viewDetail(topic)"
					>
						<div class="topics-content__topic-flex">
							<span class="topics-content__topic-title">{{topic}}</span>
							<span class="topics-content__arrow">⭢</span>
						</div>
						<hr class="topics-content__topic-divider" />
					</div>
				</div>
				<footer-bar />
			</div>
		</div>
	</div>
</template>

<script>
import HeaderBar from "../../Common/HeaderBar/HeaderBar.vue";
import FooterBar from "../../Common/FooterBar/FooterBar.vue";

export default {
	name: "topics-content",
	components: {
		HeaderBar,
		FooterBar,
	},
	props: {
		copy: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	computed: {
		topics() {
			if (this.copy.topics) {
				const topics = [];
				Object.keys(this.copy.topics).forEach((key) => {
					if (key !== "overview") {
						topics.push(this.copy.topics[key].title);
					}
				});

				return topics;
			} else {
				const topics = [];
				const domains = this.$t("components.topics.topicsContent.domains");

				Object.keys(domains).forEach((key) => {
					topics.push(domains[key].title);
				});

				return topics;
			}
		},
	},
	methods: {
		backnav() {
			this.$router.back();
		},
		viewDetail(key) {
			this.$router.push({
				name: "topic-details",
				params: {
					key: key.replace(/\s/g, "").toLowerCase().trim(),
				},
			});
		},
	},
	mounted() {
		document.getElementById("topics-content-top").scrollIntoView({ behavior: "smooth" });
	},
};
</script>

<style lang="stylus" scoped>
@import '../../../styles/global.styl';
.topics-content
	color: $white

	&__section--purple
		background-color: $purple

	&__content-boundary
		max-width: 70rem
		padding-left: 4%
		padding-right: 4%
		margin-left: auto
		margin-right: auto

	&__header
		margin-top: 0
		padding-top: $pxToRem(80)
		padding-bottom: 0
		margin-bottom: $pxToRem(40)

	&__overview
		margin-top: 0
		padding-bottom: 3rem

		& pre
			white-space: pre-wrap;
			white-space: -moz-pre-wrap;
			white-space: -pre-wrap;
			white-space: -o-pre-wrap;
			word-wrap: break-word;

	&__domains
		margin-bottom: 8rem

	&__topic
		cursor: pointer

	&__topic-flex
		display: flex
		justify-content: flex-end
		margin-bottom: $pxToRem(40)

	&__topic-title
		margin-right: auto

	&__topic-divider
		border: none
		height: 0.15rem
		color: $light-purple
		background-color: $light-purple
		margin-top: 2rem
		margin-bottom: $pxToRem(60)
</style>
